// for the sat course, these are the chapters that will be used.

export const satChapters = [
      
    {  // Chapter 1: HTML Basics
      title: "Algebra",
      order: 1,
      locked: false, 
      unlockDate: null,
     
      lessons: [
        // Lesson 1: Introduction to HTML
        {
            title: "Solving Single-Step and Multi-Step Equations",
            order: 1,
            
            content: [
              {
                type: "text",
                data:
                  "In this lesson, we’ll explore key algebraic concepts: variables, constants, and coefficients. We’ll also learn how to solve both single-step and multi-step equations by applying techniques like the distributive property and combining like terms.",
              },
              {
                type: "text",
                data:
                  "By the end of this lesson, you’ll be able to:\n\n1. Understand the role of variables, constants, and coefficients.\n2. Solve simple linear equations in one variable.\n3. Use the distributive property and combine like terms to simplify and solve equations.",
              },
              {
                type: "subtitle",
                data: "Variables, Constants, and Coefficients",
              },
              {
                type: "text",
                data:
                  "What is a Variable? A variable is a symbol (often a letter like \(x\) or \(y\)) that represents an unknown value in an equation.",
              },
              {
                type: "text",
                data:
                  "Example: In the equation \\(2x + 5 = 11\\), \\(x\\) is the variable.",
              },
              {
                type: "text",
                data:
                  "What is a Constant?\n\nA constant is a fixed number that does not change. It stands alone without being multiplied by a variable.",
              },
              {
                type: "text",
                data:
                  "Example: In \\(2x + 5 = 11\\), both \\(5\\) and \\(11\\) are constants.",
              },
              {
                type: "text",
                data:
                  "What is a Coefficient?\n\nA coefficient is the number that is multiplied by a variable in an equation.",
              },
              {
                type: "text",
                data:
                  "Example: In \\(2x + 5 = 11\\), \\(2\\) is the coefficient of \\(x\\).",
              },
              {
                type: "subtitle",
                data: "Solving Single-Step Equations",
              },
              {
                type: "text",
                data:
                  "In a single-step equation, we only need to perform one operation (addition, subtraction, multiplication, or division) to solve for the variable.",
              },
              {
                type: "steps",
                data: {
                  title: "Example 1: Solve \\(x + 4 = 10\\)",
                  steps: [
                    {
                      text: "Subtract 4 from both sides.",
                      equation: "x = 6",
                    },
                  ],
                },
              },
              {
                type: "steps",
                data: {
                  title: "Example 2: Solve \\(3x = 12\\)",
                  steps: [
                    {
                      text: "Divide both sides by 3.",
                      equation: "x = 4",
                    },
                  ],
                },
              },
              {
                type: "subtitle",
                data: "Solving Multi-Step Equations",
              },
              {
                type: "text",
                data:
                  "Multi-step equations involve more than one operation. We’ll use techniques like combining like terms and applying the distributive property to solve them.",
              },
              {
                type: "subtitle",
                data: "Combining Like Terms",
              },
              {
                type: "text",
                data:
                  "Like terms are terms that contain the same variable raised to the same power. For example, \\(2x\\) and \\(3x\\) are like terms, while \\(x\\) and \\(x^2\\) are not.",
              },
              {
                type: "steps",
                data: {
                  title: "Example: Solve \\(5x + 3x = 24\\)",
                  steps: [
                    {
                      text: "Combine the like terms \\(5x\\) and \\(3x\\).",
                      equation: "8x = 24",
                    },
                    {
                      text: "Divide both sides by 8.",
                      equation: "x = 3",
                    },
                  ],
                },
              },
              {
                type: "subtitle",
                data: "The Distributive Property",
              },
              {
                type: "text",
                data:
                  "The distributive property allows us to multiply a number by a sum or difference inside parentheses. The rule is:\n\n\\(a(b + c) = ab + ac\\).",
              },
              {
                type: "steps",
                data: {
                  title: "Example: Solve \\(2(3x + 4) = 20\\)",
                  steps: [
                    {
                      text: "Apply the distributive property.",
                      equation: "6x + 8 = 20",
                    },
                    {
                      text: "Subtract 8 from both sides.",
                      equation: "6x = 12",
                    },
                    {
                      text: "Divide both sides by 6.",
                      equation: "x = 2",
                    },
                  ],
                },
              },
              {
                type: "subtitle",
                data: "Putting It All Together",
              },
              {
                type: "text",
                data:
                  "Let’s solve a more complex equation that uses all of the above concepts.",
              },
              {
                type: "steps",
                data: {
                  title: "Example: Solve \\(4(x + 2) - 3x = 10\\)",
                  steps: [
                    {
                      text: "Apply the distributive property to expand \\(4(x + 2)\\).",
                      equation: "4x + 8 - 3x = 10",
                    },
                    {
                      text: "Combine like terms.",
                      equation: "x + 8 = 10",
                    },
                    {
                      text: "Subtract 8 from both sides.",
                      equation: "x = 2",
                    },
                  ],
                },
              },
            ],
          },           

          //lesson2 
        {
            title: "Practice",
            order: 2, 
            practice: [
              {
                type: "multiple-choice",
                question: "In the equation 3x + 7 = 19, what is the coefficient of x?",
                options: ["3", "7", "19", "x"],
                correctAnswer: "3"
              },
              {
                type: "multiple-choice",
                question: "In the equation 4x - 2 = 10, what is the constant on the left-hand side of the equation?",
                options: ["4", "x", "-2", "10"],
                correctAnswer: "2"
              },
              
              {
                type: "multiple-choice",
                question: "Which of the following is a variable?",
                options: ["5", "x", "10", "7"],
                correctAnswer: "x"
              },
              {
                type: "multiple-choice",
                question: "Solve the equation: x + 4 = 10.",
                options: ["x = 5", "x = 6", "x = 7", "x = 8"],
                correctAnswer: "x = 6"
              },
              {
                type: "multiple-choice",
                question: "What is the value of x in the equation 2x + 5 = 11?",
                options: ["x = 3", "x = 4", "x = 5", "x = 6"],
                correctAnswer: "x = 3"
              },
              {
                type: "multiple-choice",
                question: "Solve the equation: 3x = 12.",
                options: ["x = 2", "x = 3", "x = 4", "x = 5"],
                correctAnswer: "x = 4"
              },
              {
                type: "multiple-choice",
                question: "Combine the like terms: 5x + 3x.",
                options: ["8x", "2x", "15x", "5x"],
                correctAnswer: "8x"
              },
              {
                type: "multiple-choice",
                question: "Solve the equation: 4x + 8 = 24.",
                options: ["x = 3", "x = 4", "x = 5", "x = 6"],
                correctAnswer: "x = 4"
              },
              {
                type: "multiple-choice",
                question: "What is the distributive property for 2(3x + 4)?",
                options: ["6x + 4", "3x + 8", "6x + 8", "8x + 6"],
                correctAnswer: "6x + 8"
              },
              {
                type: "multiple-choice",
                question: "Solve the equation: 4(x + 2) - 3x = 10.",
                options: ["x = 2", "x = 4", "x = 5", "x = 3"],
                correctAnswer: "x = 2"
              }
            ]
          },
          
  
        // Lesson 3: HTML Forms (New Lesson)
        {
            title: "advanced",
            order: 3,
            content: [
              {
                type: "subtitle",
                data: "Lesson 2: Advanced Techniques and Applications",
              },
              {
                type: "text",
                data:
                  "In this lesson, we’ll focus on more advanced algebraic techniques: working with fractions and decimals in linear equations, checking solutions, and translating word problems into equations.",
              },
              {
                type: "text",
                data:
                  "By the end of this lesson, you’ll be able to:\n\n1. Solve linear equations involving fractions and decimals.\n2. Translate word problems into linear equations and solve them.\n3. Check your solutions to ensure they are correct.",
              },
              {
                type: "subtitle",
                data: "Working with Fractions in Linear Equations",
              },
              {
                type: "text",
                data:
                  "Fractions can make linear equations seem more complicated, but they are manageable if handled step by step. The most important strategy when dealing with fractions is to eliminate them by multiplying both sides of the equation by the least common denominator (LCD).",
              },
              {
                type: "steps",
                data: {
                  title: "Example: Solve the equation \\(\\frac{2}{3}x + 4 = 10\\)",
                  steps: [
                    {
                      text:
                        "Step 1: Multiply both sides of the equation by 3 (the denominator of the fraction) to eliminate the fraction.",
                      equation: "\\(2x + 12 = 30\\)",
                    },
                    {
                      text: "Step 2: Subtract 12 from both sides.",
                      equation: "\\(2x = 18\\)",
                    },
                    {
                      text: "Step 3: Divide both sides by 2 to isolate \\(x\\).",
                      equation: "\\(x = 9\\)",
                    },
                  ],
                  conclusion: "Now, you have solved the equation and found that \\(x = 9\\).",
                },
              },
              {
                type: "subtitle",
                data: "Working with Decimals in Linear Equations",
              },
              {
                type: "text",
                data:
                  "When working with decimals in linear equations, it’s often easier to multiply both sides of the equation by a power of 10 to eliminate the decimal. This can simplify the equation.",
              },
              {
                type: "steps",
                data: {
                  title: "Example: Solve the equation \\(0.5x + 1.2 = 6.8\\)",
                  steps: [
                    {
                      text:
                        "Step 1: Multiply both sides of the equation by 10 to eliminate the decimal.",
                      equation: "\\(5x + 12 = 68\\)",
                    },
                    {
                      text: "Step 2: Subtract 12 from both sides.",
                      equation: "\\(5x = 56\\)",
                    },
                    {
                      text: "Step 3: Divide both sides by 5 to isolate \\(x\\).",
                      equation: "\\(x = 11.2\\)",
                    },
                  ],
                  conclusion: "The solution is \\(x = 11.2\\).",
                },
              },
              {
                type: "subtitle",
                data: "Checking Solutions",
              },
              {
                type: "text",
                data:
                  "Once you’ve solved an equation, it’s important to check your solution. You can do this by substituting the value of \\(x\\) back into the original equation and ensuring both sides are equal.",
              },
              {
                type: "steps",
                data: {
                  title: "Example: Check the solution for \\(2x + 4 = 18\\), where \\(x = 7\\)",
                  steps: [
                    {
                      text: "Step 1: Substitute \\(x = 7\\) into the original equation.",
                      equation: "\\(2(7) + 4 = 18\\)",
                    },
                    {
                      text:
                        "Step 2: Simplify both sides. The left-hand side becomes \\(14 + 4 = 18\\).",
                    },
                  ],
                  conclusion:
                    "Since both sides are equal, the solution \\(x = 7\\) is correct.",
                },
              },
              {
                type: "subtitle",
                data: "Translating Word Problems into Linear Equations",
              },
              {
                type: "text",
                data:
                  "Many real-world problems can be solved by translating the information into a linear equation. The key is to identify the variable, set up the equation, and solve it.",
              },
              {
                type: "steps",
                data: {
                  title: "Example: A word problem",
                  steps: [
                    {
                      text:
                        "Problem: Sarah bought 3 notebooks and a pen for a total of $20. Each notebook costs $5. How much did the pen cost?",
                    },
                    {
                      text:
                        "Step 1: Define the variable. Let \\(x\\) be the cost of the pen.",
                    },
                    {
                      text:
                        "Step 2: Set up the equation. The total cost is 3 notebooks at $5 each, plus the pen, which gives us the equation: \\(3(5) + x = 20\\).",
                      equation: "\\(15 + x = 20\\)",
                    },
                    {
                      text: "Step 3: Solve the equation. Subtract 15 from both sides.",
                      equation: "\\(x = 5\\)",
                    },
                  ],
                  conclusion: "The pen costs $5.",
                },
              },
        
            ],
          },
            //lesson 4 

            {
                title: "Graphing and Forms of Linear Equations",
                order: 4,
                content: [
                  {
                    type: "subtitle",
                    data: "Understanding Graphs of Linear Equations",
                  },
                  {
                    type: "text",
                    data:
                      "In this lesson, we’ll explore how to graph linear equations, understand the concepts of slope and y-intercept, and utilize different forms of equations such as slope-intercept, point-slope, and standard form.",
                  },
                  {
                    type: "text",
                    data:
                      "By the end of this lesson, you’ll be able to:\n\n1. Graph linear equations on the coordinate plane.\n2. Identify and interpret the slope and y-intercept of a line.\n3. Convert between different forms of linear equations.",
                  },
                  {
                    type: "subtitle",
                    data: "Understanding Slope and Y-Intercept",
                  },
                  {
                    type: "text",
                    data:
                      "The **slope** of a line measures its steepness and direction. It is calculated as the ratio of the change in y to the change in x between two points on the line.\n\nThe **y-intercept** is the point where the line crosses the y-axis. It represents the value of y when x = 0.",
                  },
                  {
                    type: "text",
                    data:
                      "Example: In the equation y = 2x + 3, the slope is 2 and the y-intercept is 3.",
                  },
                  {
                    type: "subtitle",
                    data: "Graph of the Equation y = 2x + 3",
                  },
                  {
                    type: "graph",
                    data: {
                      title: "Graph of y = 2x + 3",
                      equation: "2 * x + 3", // The equation of the line
                      xRange: [-10, 10], // Define the x-axis range for graphing
                      step: 1, // Define the step for x values
                      graphData: {
                        labels: [-10, -5, 0, 5, 10], // Example x-values
                        datasets: [
                          {
                            label: "y = 2x + 3",
                            // Calculate corresponding y-values based on the equation y = 2x + 3
                            data: [-17, -7, 3, 13, 23], // Corresponding y-values for the given x-values
                            fill: false,
                            borderColor: "rgba(75, 192, 192, 1)",
                            tension: 0.1,
                          },
                        ],
                      },
                    },
                  },
                  {
                    type: "subtitle",
                    data: "Different Forms of Linear Equations",
                  },
                  {
                    type: "text",
                    data:
                      "There are three common forms of linear equations:\n\n1. **Slope-Intercept Form**: y = mx + b\n2. **Point-Slope Form**: y - y₁ = m(x - x₁)\n3. **Standard Form**: Ax + By = C",
                  },
                  {
                    type: "subtitle",
                    data: "Slope-Intercept Form",
                  },
                  {
                    type: "text",
                    data:
                      "The **slope-intercept form** of a linear equation is y = mx + b, where:\n\n- m is the slope of the line.\n- b is the y-intercept.",
                  },
                  {
                    type: "steps",
                    data: {
                      title: "Example: Convert to Slope-Intercept Form",
                      steps: [
                        {
                          text: "Given the equation 2x + 3y = 6, solve for y.",
                          equation: "y = -\\frac{2}{3}x + 2",
                        },
                      ],
                      conclusion:
                        "The equation in slope-intercept form is y = -\\frac{2}{3}x + 2.",
                    },
                  },
                  {
                    type: "subtitle",
                    data: "Point-Slope Form",
                  },
                  {
                    type: "text",
                    data:
                      "The **point-slope form** of a linear equation is y - y₁ = m(x - x₁), where:\n\n- m is the slope of the line.\n- (x₁, y₁) is a specific point on the line.",
                  },
                  {
                    type: "steps",
                    data: {
                      title: "Example: Write the Equation in Point-Slope Form",
                      steps: [
                        {
                          text: "Given a point (2, 3) and a slope of 4, plug these values into the point-slope formula.",
                          equation: "y - 3 = 4(x - 2)",
                        },
                      ],
                      conclusion:
                        "The equation in point-slope form is y - 3 = 4(x - 2).",
                    },
                  },
                  {
                    type: "subtitle",
                    data: "Standard Form",
                  },
                  {
                    type: "text",
                    data:
                      "The **standard form** of a linear equation is Ax + By = C, where A, B, and C are integers, and A ≥ 0.",
                  },
                  {
                    type: "steps",
                    data: {
                      title: "Example: Convert to Standard Form",
                      steps: [
                        {
                          text: "Start with the slope-intercept form y = \\frac{1}{2}x - 4.",
                          equation: "x - 2y = 8",
                        },
                      ],
                      conclusion:
                        "The equation in standard form is x - 2y = 8.",
                    },
                  },
                  {
                    type: "subtitle",
                    data: "Graphing Linear Equations",
                  },
                  {
                    type: "text",
                    data:
                      "To graph a linear equation:\n\n1. **Identify the slope and y-intercept** from the slope-intercept form.\n2. **Plot the y-intercept** on the y-axis.\n3. **Use the slope** to determine another point on the line.\n4. **Draw the line** through the plotted points.",
                  },
                  {
                    type: "steps",
                    data: {
                      title: "Example: Graph y = \\(\\frac{1}{2}x + 1\\)",
                      steps: [
                       
                        {
                          text: "Identify the slope m = \\(\\frac{1}{2}\\) and y-intercept b = 1.",
                          equation: "",
                        },
                        {
                          text: "Plot the y-intercept at (0, 1).",
                          equation: "",
                        },
                        {
                          text: "Use the slope to find another point: From (0, 1), rise 1 and run 2 to reach (2, 2).",
                          equation: "",
                        },
                        {
                          text: "Draw a line through the points (0, 1) and (2, 2).",
                          equation: "",
                        },
                      ],
                      conclusion:
                        "The graph of y = \\(\\frac{1}{2}x + 1\\) is a straight line passing through (0, 1) and (2, 2).",
                    },
                  },
                  {
                    type: "subtitle",
                    data: "Practice Problems",
                  },
                  {
                    type: "text",
                    data: "Now, it's your turn! Try solving these problems.",
                  },
                  
                ],
              },
              
              //lesson 5,
              {
                title: "Practice Problems",
                order: 5,
                practice: [
                  {
                    type: "multiple-choice",
                    question: "In the equation \\(y = 2x + 3\\), what is the slope?",
                    options: ["2", "3", "0", "1"],
                    correctAnswer: "2",
                  },
                  {
                    type: "multiple-choice",
                    question: "What is the y-intercept of the equation \\(y = -\\frac{1}{2}x + 4\\)?",
                    options: ["4", "0", "-4", "1"],
                    correctAnswer: "4",
                  },
                  {
                    type: "multiple-choice",
                    question: "Which of the following equations is in slope-intercept form?",
                    options: ["\\(Ax + By = C\\)", "\\(y - 3 = 2(x - 1)\\)", "\\(y = 4x + 1\\)", "\\(3y + 2x = 6\\)"],
                    correctAnswer: "\\(y = 4x + 1\\)",
                  },
                  {
                    type: "multiple-choice",
                    question: "If the slope of a line is 0, what type of line does it represent?",
                    options: ["Vertical line", "Horizontal line", "Diagonal line", "Curved line"],
                    correctAnswer: "Horizontal line",
                  },
                  {
                    type: "multiple-choice",
                    question: "Convert the equation \\(3x - 2y = 6\\) into slope-intercept form.",
                    options: ["\\(y = \\frac{3}{2}x + 3\\)", "\\(y = \\frac{3}{2}x - 3\\)", "\\(y = \\frac{2}{3}x - 2\\)", "\\(y = \\frac{3}{2}x + 2\\)"],
                    correctAnswer: "\\(y = \\frac{3}{2}x - 3\\)",
                  },
                  {
                    type: "multiple-choice",
                    question: "What is the standard form of the equation \\(y = 2x + 5\\)?",
                    options: ["\\(2x - y = -5\\)", "\\(y - 2x = 5\\)", "\\(y + 5 = 2x\\)", "\\(y = 2x + 5\\)"],
                    correctAnswer: "\\(2x - y = -5\\)",
                  },
                  {
                    type: "multiple-choice",
                    question: "If a line has a slope of \\(3\\) and passes through the point \\((1, 2)\\), what is the point-slope form of the equation?",
                    options: ["\\(y - 2 = 3(x - 1)\\)", "\\(y + 2 = 3(x + 1)\\)", "\\(y - 1 = 3(x + 1)\\)", "\\(y - 3 = 2(x - 1)\\)"],
                    correctAnswer: "\\(y - 2 = 3(x - 1)\\)",
                  },
                  {
                    type: "multiple-choice",
                    question: "What does the slope of a line indicate?",
                    options: ["The line's y-intercept", "The steepness and direction of the line", "The x-intercept", "The line's length"],
                    correctAnswer: "The steepness and direction of the line",
                  },
                  {
                    type: "multiple-choice",
                    question: "In the equation \\(y - 1 = -2(x - 3)\\), what is the slope?",
                    options: ["-2", "2", "1", "3"],
                    correctAnswer: "-2",
                  },
                  {
                    type: "multiple-choice",
                    question: "If the equation of a line is \\(y = -4x + 1\\), what is the y-intercept?",
                    options: ["-4", "1", "0", "4"],
                    correctAnswer: "1",
                  },
                  {
                    type: "multiple-choice",
                    question: "Solve for \\(x\\): If \\(y = 2x + 5\\) and \\(y = 15\\), what is \\(x\\)?",
                    options: ["5", "7.5", "10", "2.5"],
                    correctAnswer: "5",
                  },
                ],
              },

            //lesson 6
            {
                title: "Parallel and Perpendicular Lines & Real-World Applications",
                order: 6,
                content: [
                  {
                    type: "subtitle",
                    data: "Understanding Parallel and Perpendicular Lines",
                  },
                  {
                    type: "text",
                    data: "In this lesson, we’ll explore the characteristics of parallel and perpendicular lines. We will also look at real-world applications of linear equations.",
                  },
                  {
                    type: "text",
                    data: "By the end of this lesson, you’ll be able to:\n\n1. Identify parallel and perpendicular lines based on their slopes.\n2. Write equations for lines that are parallel or perpendicular to a given line.\n3. Apply these concepts to solve real-world problems.",
                  },
                  {
                    type: "subtitle",
                    data: "Characteristics of Parallel Lines",
                  },
                  {
                    type: "text",
                    data: "Parallel lines have the same slope but different y-intercepts. This means they will never intersect. For example, if we have a line with the equation \\(y = 2x + 1\\), any line parallel to it will also have a slope of 2.",
                  },
                  {
                    type: "text",
                    data: "Example: If you want to write an equation of a line parallel to \\(y = 2x + 1\\) that passes through the point (3, 4), you would use the same slope:\n\n- Slope = 2\n- Point = (3, 4)\n- Using the point-slope form: \\(y - y_1 = m(x - x_1)\\)\n- The equation becomes: \\(y - 4 = 2(x - 3)\\).",
                  },
                  {
                    type: "subtitle",
                    data: "Characteristics of Perpendicular Lines",
                  },
                  {
                    type: "text",
                    data: "Perpendicular lines have slopes that are negative reciprocals of each other. This means that if the slope of one line is \\(m\\), the slope of the line perpendicular to it is \\(-\\frac{1}{m}\\).",
                  },
                  {
                    type: "text",
                    data: "Example: If a line has a slope of 2, then the slope of the line perpendicular to it would be \\(-\\frac{1}{2}\\). If the original line is given by \\(y = 2x + 1\\), then the equation of the perpendicular line passing through (3, 4) would be:\n\n- Slope = \\(-\\frac{1}{2}\\)\n- Using the point-slope form: \\(y - 4 = -\\frac{1}{2}(x - 3)\\).",
                  },
                  {
                    type: "subtitle",
                    data: "Real-World Applications",
                  },
                  {
                    type: "text",
                    data: "Understanding parallel and perpendicular lines has real-world applications in various fields, such as architecture, engineering, and computer graphics.",
                  },
                  {
                    type: "text",
                    data: "For instance, in urban planning, roads that run parallel to each other are designed with the same slope to maintain consistent gradients for drainage.",
                  },
                  {
                    type: "text",
                    data: "Example Problem: If two streets are perpendicular and one street has a slope of \\(2\\), what is the slope of the other street?\n\nThe slope of the other street would be \\(-\\frac{1}{2}\\).",
                  },
                  {
                    type: "subtitle",
                    data: "Practice Problems",
                  },
                  {
                    type: "text",
                    data: "Now, it's your turn! Try solving these problems:",
                  },
                  {
                    type: "practice",
                    data: {
                      problems: [
                        {
                          type: "multiple-choice",
                          question: "What is the slope of a line parallel to \\(y = 3x - 5\\)?",
                          options: ["3", "-3", "5", "-5"],
                          correctAnswer: "3",
                        },
                        {
                          type: "multiple-choice",
                          question: "If a line has a slope of \\(-4\\), what is the slope of a line perpendicular to it?",
                          options: ["\\(\\frac{1}{4}\\)", "4", "-4", "None of the above"],
                          correctAnswer: "\\(\\frac{1}{4}\\)",
                        },
                        {
                          type: "multiple-choice",
                          question: "Which of the following equations represents a line parallel to \\(y = -2x + 3\\)?",
                          options: [
                            "y = -2x + 5",
                            "y = 0.5x - 1",
                            "y = 2x + 1",
                            "y = -0.5x + 1",
                          ],
                          correctAnswer: "y = -2x + 5",
                        },
                        {
                          type: "multiple-choice",
                          question: "The slope of a line perpendicular to \\(y = 4x + 1\\) is:",
                          options: ["4", "-4", "0.25", "-0.25"],
                          correctAnswer: "-0.25",
                        },
                      ],
                    },
                  },
                ],
              },

              // Title: Practice Questions - Parallel and Perpendicular Lines & Real-World Applications

{
    title: "Practice",
    order: 7, 
    practice: [
      {
        type: "multiple-choice",
        question: "What is the slope of a line parallel to the equation \\(y = 2x + 5\\)?",
        options: ["2", "5", "-2", "0"],
        correctAnswer: "2"
      },
      {
        type: "multiple-choice",
        question: "Which of the following correctly represents a line perpendicular to \\(y = 4x - 3\\)?",
        options: ["\\(y = \\frac{1}{4}x + 2\\)", "\\(y = -4x + 5\\)", "\\(y = -\\frac{1}{4}x + 3\\)", "\\(y = 4x + 7\\)"],
        correctAnswer: "\\(y = -\\frac{1}{4}x + 3\\)"
      },
      {
        type: "multiple-choice",
        question: "Two lines are parallel. If one line has the equation \\(y = -3x + 4\\), which of the following could be the equation of the other line?",
        options: ["\\(y = -3x - 5\\)", "\\(y = 3x + 1\\)", "\\(y = \\frac{1}{3}x + 2\\)", "\\(y = -\\frac{1}{3}x + 6\\)"],
        correctAnswer: "\\(y = -3x - 5\\)"
      },
      {
        type: "multiple-choice",
        question: "A road has a slope of \\(\\frac{2}{3}\\). What is the slope of a road that is perpendicular to it?",
        options: ["\\(\\frac{3}{2}\\)", "\\(-\\frac{2}{3}\\)", "\\(\\frac{1}{2}\\)", "\\(-\\frac{3}{2}\\)"],
        correctAnswer: "\\(-\\frac{3}{2}\\)"
      },
      {
        type: "multiple-choice",
        question: "Given the equation \\(y = -\\frac{1}{2}x + 6\\), what is the equation of a line parallel to it that passes through the point (2, 4)?",
        options: ["\\(y - 4 = -\\frac{1}{2}(x - 2)\\)", "\\(y + 4 = \\frac{1}{2}(x - 2)\\)", "\\(y - 4 = \\frac{1}{2}(x - 2)\\)", "\\(y + 4 = -\\frac{1}{2}(x + 2)\\)"],
        correctAnswer: "\\(y - 4 = -\\frac{1}{2}(x - 2)\\)"
      },
      {
        type: "multiple-choice",
        question: "In the context of urban planning, what is the relationship between two streets if one street has a slope of 3 and the other has a slope of \\(-\\frac{1}{3}\\)?",
        options: ["The streets are parallel", "The streets are perpendicular", "The streets intersect but are not perpendicular", "The streets have the same slope"],
        correctAnswer: "The streets are perpendicular"
      },
      {
        type: "multiple-choice",
        question: "A line passes through the point (0, 1) and is perpendicular to the line \\(y = 5x + 2\\). What is the slope of the perpendicular line?",
        options: ["5", "\\(-5\\)", "\\(\\frac{1}{5}\\)", "\\(-\\frac{1}{5}\\)"],
        correctAnswer: "\\(-\\frac{1}{5}\\)"
      },
      {
        type: "multiple-choice",
        question: "Which of the following equations represents a line parallel to \\(y = 2x - 7\\)?",
        options: ["\\(y = 2x + 3\\)", "\\(y = \\frac{1}{2}x + 5\\)", "\\(y = -2x + 4\\)", "\\(y = 2x + 7\\)"],
        correctAnswer: "\\(y = 2x + 3\\)"
      },
      {
        type: "multiple-choice",
        question: "A line has the equation \\(y = -\\frac{4}{5}x + 3\\). What is the slope of a line perpendicular to this one?",
        options: ["\\(-\\frac{5}{4}\\)", "\\(\\frac{4}{5}\\)", "\\(\\frac{5}{4}\\)", "\\(-\\frac{4}{5}\\)"],
        correctAnswer: "\\(\\frac{5}{4}\\)"
      },
      {
        type: "multiple-choice",
        question: "Write the equation of a line perpendicular to \\(y = -2x + 5\\) that passes through the point (1, -3).",
        options: ["\\(y + 3 = \\frac{1}{2}(x - 1)\\)", "\\(y - 3 = 2(x + 1)\\)", "\\(y + 3 = -2(x - 1)\\)", "\\(y + 3 = \\frac{1}{2}(x - 1)\\)"],
        correctAnswer: "\\(y + 3 = \\frac{1}{2}(x - 1)\\)"
      }
    ],
  },
  
  // End of Practice Questions

// Title: Introduction to Linear Functions and Graphing

{
    title: "Introduction to Linear Functions and Graphing",
    order: 8,
    content: [
      {
        type: "subtitle",
        data: "Understanding Linear Functions",
      },
      {
        type: "text",
        data:
          "A linear function is a function that can be graphed as a straight line in the coordinate plane. It has the general form \\(y = mx + b\\), where \\(m\\) is the slope and \\(b\\) is the y-intercept. Linear functions are important because they model constant rates of change.",
      },
      {
        type: "subtitle",
        data: "Slope and Y-Intercept",
      },
      {
        type: "text",
        data:
          "The slope of a linear function represents the rate of change, or how much \\(y\\) changes for a given change in \\(x\\). It is calculated by dividing the change in \\(y\\) by the change in \\(x\\), \\(m = \\frac{\\Delta y}{\\Delta x}\\). \n\nThe y-intercept is the value of \\(y\\) when \\(x = 0\\), indicating where the line crosses the y-axis.",
      },
      {
        type: "steps",
        data: {
          title: "Example: Finding the Slope and Y-Intercept",
          steps: [
            {
              text: "Consider the equation \\(y = 2x + 3\\).",
              equation: "",
            },
            {
              text: "The slope \\(m = 2\\) means that for every 1 unit increase in \\(x\\), \\(y\\) increases by 2 units.",
              equation: "",
            },
            {
              text: "The y-intercept \\(b = 3\\) means that the line crosses the y-axis at the point (0, 3).",
              equation: "",
            },
          ],
          conclusion: "Thus, the slope is 2, and the y-intercept is 3 in the equation \\(y = 2x + 3\\).",
        },
      },
      {
        type: "subtitle",
        data: "Graphing Linear Functions",
      },
      {
        type: "text",
        data:
          "To graph a linear function, you need to identify the slope and y-intercept. Start by plotting the y-intercept on the graph, then use the slope to find other points along the line. Connect the points to form a straight line.",
      },
      {
        type: "steps",
        data: {
          title: "Example: Graph the Linear Function",
          steps: [
            {
              text: "Take the equation \\(y = -\\frac{1}{2}x + 4\\).",
              equation: "",
            },
            {
              text: "The slope is \\(-\\frac{1}{2}\\), which means for every 2 units increase in \\(x\\), \\(y\\) decreases by 1 unit.",
              equation: "",
            },
            {
              text: "The y-intercept is 4, so the graph will cross the y-axis at the point (0, 4).",
              equation: "",
            },
            {
              text: "From the point (0, 4), apply the slope to find another point, such as (2, 3).",
              equation: "",
            },
          ],
          conclusion:
            "The graph of \\(y = -\\frac{1}{2}x + 4\\) is a straight line passing through (0, 4) and (2, 3).",
        },
      },
      {
        type: "graph",
        data: {
            title: "Graph of \\(y = -\\frac{1}{2}x + 4\\)",
          equation: "-(1 / 2) * x + 4", // The equation for the graph
          xRange: [0, 10], // Define the x-axis range for graphing
          step: 1, // Define the step for x values
          graphData: {
            labels: [0, 2, 4, 6, 8, 10], // Example x-values
            datasets: [
              {
                label: "y = -\\frac{1}{2}x + 4",
                // Calculate corresponding y-values based on the equation y = -\\frac{1}{2}x + 4
                data: [4, 3, 2, 1, 0, -1], // Corresponding y-values for the given x-values
                fill: false,
                borderColor: "rgba(75, 192, 192, 1)",
                tension: 0.1,
              },
            ],
          },
        },
      },
      {
        type: "subtitle",
        data: "Function Notation",
      },
      {
        type: "text",
        data:
          "Function notation is a way to represent functions more compactly. Instead of writing \\(y = mx + b\\), we can use \\(f(x) = mx + b\\). In this case, \\(f(x)\\) represents the value of the function at \\(x\\), and we read it as 'f of x'.",
      },
      {
        type: "steps",
        data: {
          title: "Example: Using Function Notation",
          steps: [
            {
              text: "If \\(f(x) = 3x + 2\\), find \\(f(4)\\).",
              equation: "f(4) = 3(4) + 2 = 12 + 2 = 14",
            },
          ],
          conclusion: "Therefore, \\(f(4) = 14\\) for the function \\(f(x) = 3x + 2\\).",
        },
      },
      {
        type: "subtitle",
        data: "Real-World Applications of Linear Functions",
      },
      {
        type: "text",
        data:
          "Linear functions are used in many real-world scenarios, such as calculating costs based on a fixed rate (e.g., \$5 per item), predicting growth over time, or modeling relationships between variables.",
      },
      {
        type: "steps",
        data: {
          title: "Example: Real-World Application",
          steps: [
            {
              text: "A plumber charges a flat fee of \$50 for a service call, plus \$30 per hour of work. The total cost can be represented by the linear function \\(C(x) = 30x + 50\\), where \\(x\\) is the number of hours worked.",
              equation: "",
            },
          ],
          conclusion: "The plumber's cost increases linearly with the number of hours worked.",
        },
      },
      {
        type: "graph",
        data: {
          title: "Graph of C(x) = 30x + 50",
          equation: "30 * x + 50", // The equation for the graph
          xRange: [0, 10], // Define the x-axis range for graphing
          step: 1, // Define the step for x values
          graphData: {
            labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], // Example x-values
            datasets: [
              {
                label: "C(x) = 30x + 50",
                // Calculate corresponding y-values based on the equation C(x) = 30x + 50
                data: [50, 80, 110, 140, 170, 200, 230, 260, 290, 320, 350], // Corresponding y-values
                fill: false,
                borderColor: "rgba(75, 192, 192, 1)",
                tension: 0.1,
              },
            ],
          },
        },
      },
      {
        type: "subtitle",
        data: "Practice Problems",
      },
      {
        type: "text",
        data: "Try solving the following problems to apply what you've learned.",
      },
    ],
  },
  
  // End of lesson

 
  
  {
    "title": "Practice",
    "order": 9, 
    "practice": [
      {
        "type": "multiple-choice",
        "question": "What is the y-intercept of the linear function \\(y = 3x - 4\\)?",
        "options": ["3", "-4", "4", "-3"],
        "correctAnswer": "-4"
      },
      {
        "type": "multiple-choice",
        "question": "Which of the following correctly represents the slope of the equation \\(y = -\\frac{2}{3}x + 5\\)?",
        "options": ["\\(-\\frac{2}{3}\\)", "5", "3", "2"],
        "correctAnswer": "\\(-\\frac{2}{3}\\)"
      },
      {
        "type": "multiple-choice",
        "question": "If a line passes through the point (1, 2) and has a slope of 3, which of the following equations represents this line?",
        "options": ["\\(y - 2 = 3(x - 1)\\)", "\\(y + 2 = 3(x + 1)\\)", "\\(y - 2 = 3(x + 1)\\)", "\\(y + 2 = -3(x - 1)\\)"],
        "correctAnswer": "\\(y - 2 = 3(x - 1)\\)"
      },
      {
        "type": "multiple-choice",
        "question": "What is the slope of a line perpendicular to \\(y = \\frac{3}{4}x - 1\\)?",
        "options": ["\\(\\frac{4}{3}\\)", "\\(-\\frac{4}{3}\\)", "\\(\\frac{3}{4}\\)", "\\(-\\frac{3}{4}\\)"],
        "correctAnswer": "\\(-\\frac{4}{3}\\)"
      },
      {
        "type": "multiple-choice",
        "question": "A line has the equation \\(y = 6x + 2\\). What is the slope of a line parallel to this one?",
        "options": ["2", "6", "-6", "\\(-\\frac{1}{6}\\)"],
        "correctAnswer": "6"
      },
      {
        "type": "multiple-choice",
        "question": "Given the function \\(f(x) = 2x + 5\\), what is the value of \\(f(3)\\)?",
        "options": ["11", "7", "8", "13"],
        "correctAnswer": "11"
      },
      {
        "type": "multiple-choice",
        "question": "If a line passes through the points (2, 3) and (4, 7), what is the slope of the line?",
        "options": ["2", "1", "4", "\\(\\frac{1}{2}\\)"],
        "correctAnswer": "2"
      },
      {
        "type": "multiple-choice",
        "question": "Which of the following functions is written in function notation?",
        "options": ["\\(y = 2x + 1\\)", "\\(f(x) = 2x + 1\\)", "\\(y + 1 = 2(x - 2)\\)", "\\(f(2) = 2x + 1\\)"],
        "correctAnswer": "\\(f(x) = 2x + 1\\)"
      },
      {
        "type": "multiple-choice",
        "question": "What is the value of \\(x\\) if \\(f(x) = 0\\) in the function \\(f(x) = 4x - 8\\)?",
        "options": ["1", "2", "4", "8"],
        "correctAnswer": "2"
      },
      {
        "type": "multiple-choice",
        "question": "Which of the following represents a real-world application of a linear function?",
        "options": ["The height of a bouncing ball over time", "The cost of a plumber charging a flat fee and an hourly rate", "The population growth of a city", "The temperature change over a 24-hour period"],
        "correctAnswer": "The cost of a plumber charging a flat fee and an hourly rate"
      }
    ],
},

  

  // Title: Slope, Transformations, and Real-World Interpretation

{
    title: "Slope, Transformations, and Real-World Interpretation",
    order: 10,
    content: [
      {
        type: "subtitle",
        data: "Understanding Slope as Rate of Change",
      },
      {
        type: "text",
        data:
          "The **slope** of a line represents the rate of change between two variables. It tells us how much the dependent variable \\(y\\) changes for a unit change in the independent variable \\(x\\). Mathematically, it is calculated as the ratio of the change in \\(y\\) to the change in \\(x\\), which is:\n\n\\( \\text{slope} = m = \\frac{\\Delta y}{\\Delta x} \\).",
      },
      {
        type: "steps",
        data: {
          title: "Example: Calculating the Slope",
          steps: [
            {
              text: "Given two points \\((x_1, y_1) = (2, 3)\\) and \\((x_2, y_2) = (5, 11)\\), find the slope of the line passing through them.",
              equation: "",
            },
            {
              text: "Calculate the change in \\(y\\): \\( \\Delta y = y_2 - y_1 = 11 - 3 = 8 \\).",
              equation: "",
            },
            {
              text: "Calculate the change in \\(x\\): \\( \\Delta x = x_2 - x_1 = 5 - 2 = 3 \\).",
              equation: "",
            },
            {
              text: "Compute the slope: \\( m = \\frac{\\Delta y}{\\Delta x} = \\frac{8}{3} \\).",
              equation: "",
            },
          ],
          conclusion: "The slope of the line is \\( \\frac{8}{3} \\).",
        },
      },
      {
        type: "subtitle",
        data: "Domain and Range",
      },
      {
        type: "text",
        data:
          "The **domain** of a function is the set of all possible input values \\(x\\) for which the function is defined, while the **range** is the set of all possible output values \\(y\\). Understanding the domain and range is crucial for analyzing functions and their graphs.",
      },
      {
        type: "steps",
        data: {
          title: "Example: Finding Domain and Range",
          steps: [
            {
              text: "Consider the function \\( f(x) = 2x + 3 \\).",
              equation: "",
            },
            {
              text: "Since this is a linear function without any restrictions, the domain is all real numbers.",
              equation: "",
            },
            {
              text: "Similarly, the range is also all real numbers because as \\( x \\) takes any real value, \\( y \\) can be any real number.",
              equation: "",
            },
          ],
          conclusion: "Therefore, the domain and range of \\( f(x) = 2x + 3 \\) are both all real numbers.",
        },
      },
      {
        type: "subtitle",
        data: "Transformations of Functions",
      },
      {
        type: "text",
        data:
          "Transformations alter the appearance of a function's graph. The main types of transformations are **translations**, **reflections**, **stretches**, and **compressions**.",
      },
      {
        type: "text",
        data:
          "A general form of a transformed function is:\n\n\\( y = a \\cdot f(b(x - h)) + k \\),\n\nwhere:\n- \\( a \\) affects vertical stretching or compression and reflection over the \\( x \\)-axis.\n- \\( b \\) affects horizontal stretching or compression and reflection over the \\( y \\)-axis.\n- \\( h \\) represents horizontal translation (shift left or right).\n- \\( k \\) represents vertical translation (shift up or down).",
      },
      {
        type: "steps",
        data: {
          title: "Example: Applying Transformations",
          steps: [
            {
              text: "Start with the basic function \\( f(x) = x^2 \\).",
              equation: "",
            },
            {
              text: "Apply a vertical stretch by a factor of 2: \\( g(x) = 2x^2 \\).",
              equation: "",
            },
            {
              text: "Shift the graph to the right by 3 units: \\( h(x) = 2(x - 3)^2 \\).",
              equation: "",
            },
            {
              text: "Shift the graph up by 4 units: \\( k(x) = 2(x - 3)^2 + 4 \\).",
              equation: "",
            },
          ],
          conclusion: "The transformed function is \\( k(x) = 2(x - 3)^2 + 4 \\).",
        },
      },
      {
        type: "graph",
        data: {
          title: "Graph of \\( y = 2(x - 3)^2 + 4 \\)",
          equation: "2*(x - 3)^2 + 4",
          xRange: [0, 6],
          step: 0.1,
        },
      },
      {
        type: "subtitle",
        data: "Interpreting Function Properties from Graphs or Equations",
      },
      {
        type: "text",
        data:
          "Analyzing the graph or equation of a function allows us to determine key properties such as intercepts, intervals of increase or decrease, maximum and minimum values, and asymptotes.",
      },
      {
        type: "steps",
        data: {
          title: "Example: Interpreting a Linear Function",
          steps: [
            {
              text: "Given the equation \\( y = -\\frac{1}{2}x + 5 \\), identify the slope and y-intercept.",
              equation: "",
            },
            {
              text: "The slope \\( m = -\\frac{1}{2} \\) indicates the function is decreasing.",
              equation: "",
            },
            {
              text: "The y-intercept is at \\( (0, 5) \\).",
              equation: "",
            },
            {
              text: "The x-intercept can be found by setting \\( y = 0 \\): \\( 0 = -\\frac{1}{2}x + 5 \\) leads to \\( x = 10 \\).",
              equation: "",
            },
          ],
          conclusion:
            "The function decreases at a constant rate, crosses the y-axis at (0, 5), and the x-axis at (10, 0).",
        },
      },
      {
        type: "graph",
        data: {
          title: "Graph of \\( y = -\\frac{1}{2}x + 5 \\)",
          equation: "-(1/2)*x + 5",
          xRange: [0, 12],
          step: 0.1,
        },
      },
      {
        type: "subtitle",
        data: "Real-World Applications",
      },
      {
        type: "text",
        data:
          "Understanding slope and transformations is essential in real-world contexts like physics for velocity, economics for cost functions, and engineering for signal processing.",
      },
      {
        type: "steps",
        data: {
          title: "Example: Slope as Rate of Change",
          steps: [
            {
              text: "A car travels from point A to point B, covering a distance of 150 miles in 3 hours.",
              equation: "",
            },
            {
              text: "Calculate the average speed (rate of change of distance with respect to time).",
              equation: "",
            },
            {
              text: "Average speed \\( = \\frac{\\text{Total Distance}}{\\text{Total Time}} = \\frac{150 \\text{ miles}}{3 \\text{ hours}} = 50 \\text{ mph} \\).",
              equation: "",
            },
          ],
          conclusion: "The car's average speed is 50 miles per hour.",
        },
      },
      {
        type: "text",
        data:
          "This example shows how the concept of slope as a rate of change applies to real-life scenarios.",
      },
      {
        type: "subtitle",
        data: "Practice Problems",
      },
      {
        type: "text",
        data:
          "Try solving the following problems to reinforce your understanding:",
      },
      {
        type: "steps",
        data: {
          title: "Practice Problem 1",
          steps: [
            {
              text: "Given the function \\( f(x) = -3x + 2 \\), find the slope and y-intercept.",
              equation: "",
            },
          ],
          conclusion: "",
        },
      },
      {
        type: "steps",
        data: {
          title: "Practice Problem 2",
          steps: [
            {
              text: "Determine the domain and range of the function \\( f(x) = \\sqrt{x - 1} \\).",
              equation: "",
            },
          ],
          conclusion: "",
        },
      },
      {
        type: "steps",
        data: {
          title: "Practice Problem 3",
          steps: [
            {
              text: "Apply a horizontal shift of 2 units to the right and a reflection over the x-axis to the function \\( f(x) = |x| \\). Write the equation of the transformed function.",
              equation: "",
            },
          ],
          conclusion: "",
        },
      },
    ],
  },
  
  // End of lesson

  {
    "title": "Practice",
    "order": 11, 
    "practice": [
      {
        "type": "multiple-choice",
        "question": "What is the slope of the line that passes through the points (3, 5) and (7, 13)?",
        "options": ["\\(\\frac{2}{1}\\)", "\\(\\frac{4}{3}\\)", "2", "3"],
        "correctAnswer": "2"
      },
      {
        "type": "multiple-choice",
        "question": "Which of the following represents a vertical shift of 3 units up for the function \\(f(x) = 2x + 1\\)?",
        "options": ["\\(f(x) = 2x + 4\\)", "\\(f(x) = 2x - 3\\)", "\\(f(x) = 2x + 1\\)", "\\(f(x) = 2x + 3\\)"],
        "correctAnswer": "\\(f(x) = 2x + 4\\)"
      },
      {
        "type": "multiple-choice",
        "question": "If a function has the equation \\(f(x) = 3(x - 2)^2 + 5\\), which transformation has occurred?",
        "options": ["Horizontal shift 2 units right and vertical shift 5 units up", "Horizontal shift 2 units left and vertical stretch", "Reflection over the y-axis and shift 5 units up", "Reflection over the x-axis and shift 2 units left"],
        "correctAnswer": "Horizontal shift 2 units right and vertical shift 5 units up"
      },
      {
        "type": "multiple-choice",
        "question": "Find the domain of the function \\(f(x) = 2x - 1\\).",
        "options": ["All real numbers", "Only positive numbers", "Only negative numbers", "Numbers greater than zero"],
        "correctAnswer": "All real numbers"
      },
      {
        "type": "multiple-choice",
        "question": "What is the average speed if a car travels 120 miles in 2 hours?",
        "options": ["30 mph", "50 mph", "60 mph", "70 mph"],
        "correctAnswer": "60 mph"
      },
      {
        "type": "multiple-choice",
        "question": "What is the effect of multiplying the function \\(f(x) = x^2\\) by 3?",
        "options": ["Vertical stretch", "Vertical compression", "Horizontal stretch", "Reflection over the x-axis"],
        "correctAnswer": "Vertical stretch"
      },
      {
        "type": "multiple-choice",
        "question": "If the function \\(f(x) = -\\frac{1}{2}x + 7\\) is reflected over the x-axis, what is the new equation?",
        "options": ["\\(f(x) = \\frac{1}{2}x - 7\\)", "\\(f(x) = -\\frac{1}{2}x - 7\\)", "\\(f(x) = \\frac{1}{2}x + 7\\)", "\\(f(x) = -\\frac{1}{2}x + 7\\)"],
        "correctAnswer": "\\(f(x) = \\frac{1}{2}x - 7\\)"
      },
      {
        "type": "multiple-choice",
        "question": "Which of the following describes the transformation applied to \\(f(x) = x\\) to obtain \\(f(x) = -x\\)?",
        "options": ["Reflection over the x-axis", "Reflection over the y-axis", "Vertical shift", "Horizontal shift"],
        "correctAnswer": "Reflection over the y-axis"
      },
      {
        "type": "multiple-choice",
        "question": "What is the range of the function \\(f(x) = 3x + 2\\)?",
        "options": ["All real numbers", "All positive numbers", "Only integers", "Values greater than 2"],
        "correctAnswer": "All real numbers"
      },
      {
        "type": "multiple-choice",
        "question": "Which of the following transformations results in a horizontal shift of 4 units to the left for the function \\(f(x) = x^2\\)?",
        "options": ["\\(f(x) = (x + 4)^2\\)", "\\(f(x) = (x - 4)^2\\)", "\\(f(x) = 4x^2\\)", "\\(f(x) = \\frac{1}{4}x^2\\)"],
        "correctAnswer": "\\(f(x) = (x + 4)^2\\)"
      }
    ],
},

{
    "title": "Solving Systems of Equations (Graphical and Algebraic Methods)",
    "order": 12,
    "content": [
        {
            "type": "subtitle",
            "data": "Introduction to Systems of Equations"
        },
        {
            "type": "text",
            "data": "A system of equations consists of two or more equations with the same set of variables. The solution to the system is the set of values for the variables that make all the equations true."
        },
        {
            "type": "subtitle",
            "data": "Methods of Solving Systems of Equations"
        },
        {
            "type": "text",
            "data": "There are three main methods for solving systems of equations: graphing, substitution, and elimination. Each method provides a way to find the point where two lines intersect, which represents the solution to the system."
        },
        {
            "type": "subtitle",
            "data": "Graphical Method"
        },
        {
            "type": "text",
            "data": "In the graphical method, you plot both equations on a coordinate plane and look for the point where the two lines intersect. This point represents the solution to the system."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Solve by Graphing",
                "steps": [
                    {
                        "text": "Consider the system: \\(y = 2x + 3\\) and \\(y = -x + 1\\).",
                        "equation": ""
                    },
                    {
                        "text": "Graph both equations on the same coordinate plane.",
                        "equation": ""
                    },
                    {
                        "text": "The lines intersect at the point (1, 5).",
                        "equation": ""
                    }
                ],
                "conclusion": "The solution to the system is \\((1, 5)\\)."
            }
        },
        {
            "type": "subtitle",
            "data": "Substitution Method"
        },
        {
            "type": "text",
            "data": "The substitution method involves solving one equation for one variable and then substituting that expression into the other equation."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Solve by Substitution",
                "steps": [
                    {
                        "text": "Consider the system: \\(y = 2x + 3\\) and \\(x + y = 5\\).",
                        "equation": ""
                    },
                    {
                        "text": "Solve the first equation for y: \\(y = 2x + 3\\).",
                        "equation": ""
                    },
                    {
                        "text": "Substitute \\(y = 2x + 3\\) into the second equation: \\(x + (2x + 3) = 5\\).",
                        "equation": ""
                    },
                    {
                        "text": "Solve for x: \\(3x + 3 = 5\\), \\(x = \\frac{2}{3}\\).",
                        "equation": ""
                    },
                    {
                        "text": "Substitute \\(x = \\frac{2}{3}\\) into \\(y = 2x + 3\\) to find \\(y = 4\\).",
                        "equation": ""
                    }
                ],
                "conclusion": "The solution to the system is \\(x = \\frac{2}{3}, y = 4\\)."
            }
        },
        {
            "type": "subtitle",
            "data": "Elimination Method"
        },
        {
            "type": "text",
            "data": "The elimination method involves adding or subtracting the equations to eliminate one of the variables."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Solve by Elimination",
                "steps": [
                    {
                        "text": "Consider the system: \\(2x + 3y = 7\\) and \\(4x - 3y = 1\\).",
                        "equation": ""
                    },
                    {
                        "text": "Add the two equations to eliminate y: \\(2x + 3y + 4x - 3y = 7 + 1\\).",
                        "equation": "\\(6x = 8\\)"
                    },
                    {
                        "text": "Solve for x: \\(x = \\frac{4}{3}\\).",
                        "equation": ""
                    },
                    {
                        "text": "Substitute \\(x = \\frac{4}{3}\\) into one of the original equations to find y.",
                        "equation": ""
                    }
                ],
                "conclusion": "The solution to the system is \\(x = \\frac{4}{3}, y = 2\\)."
            }
        },
        {
            "type": "subtitle",
            "data": "Practice Problems"
        },
        {
            "type": "text",
            "data": "Now that you've learned about solving systems of equations using different methods, try solving the following problems."
        }
    ],
},

{
    "title": "Practice",
    "order": 13, 
    "practice": [
        {
            "type": "multiple-choice",
            "question": "What is the solution to the system \\(y = 2x + 1\\) and \\(y = -x + 3\\)?",
            "options": ["\\((1, 2)\\)", "\\((2, 3)\\)", "\\((1, 3)\\)", "\\((0, 1)\\)"],
            "correctAnswer": "\\((1, 3)\\)"
        },
        {
            "type": "multiple-choice",
            "question": "What is the next step in solving the system \\(x + y = 5\\) and \\(y = 2x + 1\\) using substitution?",
            "options": ["Substitute \\(x = 5\\) into the second equation", "Substitute \\(y = 2x + 1\\) into the first equation", "Solve for y in the first equation", "Eliminate one of the variables"],
            "correctAnswer": "Substitute \\(y = 2x + 1\\) into the first equation"
        },
        {
            "type": "multiple-choice",
            "question": "Which method is most appropriate if the equations \\(2x + 3y = 7\\) and \\(4x - 3y = 1\\) are to be solved?",
            "options": ["Graphing", "Substitution", "Elimination", "Trial and error"],
            "correctAnswer": "Elimination"
        },
        {
            "type": "multiple-choice",
            "question": "If two lines intersect at exactly one point, what can be said about the system of equations?",
            "options": ["It has no solution", "It has one solution", "It has infinitely many solutions", "It has two solutions"],
            "correctAnswer": "It has one solution"
        },
        {
            "type": "multiple-choice",
            "question": "In the elimination method, what should you do if the coefficients of one variable are already opposites in the two equations?",
            "options": ["Add the equations", "Subtract the equations", "Solve for one variable", "Substitute values"],
            "correctAnswer": "Add the equations"
        }
    ],
},

{
    "title": "Applications and Consistency of Systems",
    "order": 14,
    "content": [
        {
            "type": "subtitle",
            "data": "Introduction to Word Problems and Systems of Equations"
        },
        {
            "type": "text",
            "data": "Systems of equations can be used to solve real-world problems. These problems often involve finding the relationship between two or more quantities and require solving multiple equations simultaneously."
        },
        {
            "type": "subtitle",
            "data": "Steps for Solving Word Problems with Systems of Equations"
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Solving a Word Problem Using Systems of Equations",
                "steps": [
                    {
                        "text": "Step 1: Define your variables. Example: A movie theater sells adult tickets for $10 and child tickets for $6. If the total sales were $84 and 10 tickets were sold, how many of each ticket type were sold? Define \\(x\\) as the number of adult tickets sold and \\(y\\) as the number of child tickets sold.",
                        "equation": ""
                    },
                    {
                        "text": "Step 2: Set up the system of equations. From the problem, we know the total number of tickets is 10: \\(x + y = 10\\). We also know the total sales: \\(10x + 6y = 84\\).",
                        "equation": ""
                    },
                    {
                        "text": "Step 3: Solve the system. Use substitution or elimination to solve the system: \\(x + y = 10\\) and \\(10x + 6y = 84\\).",
                        "equation": ""
                    }
                ],
                "conclusion": "The solution to this system tells us the number of adult and child tickets sold. For this problem, we find that \\(x = 6\\) and \\(y = 4\\), meaning 6 adult tickets and 4 child tickets were sold."
            }
        },
        {
            "type": "subtitle",
            "data": "Understanding Consistent, Inconsistent, and Dependent Systems"
        },
        {
            "type": "text",
            "data": "When solving systems of equations, it's important to understand whether the system has a solution. There are three types of systems based on consistency: consistent, inconsistent, and dependent."
        },
        {
            "type": "subtitle",
            "data": "Consistent Systems"
        },
        {
            "type": "text",
            "data": "A consistent system has at least one solution. These systems include independent systems with exactly one solution (where the lines intersect at one point) and dependent systems where there are infinitely many solutions (the lines are coincident)."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Consistent Independent System",
                "steps": [
                    {
                        "text": "Consider the system \\(y = 2x + 3\\) and \\(y = -x + 1\\). These two lines intersect at exactly one point, making the system consistent and independent.",
                        "equation": ""
                    }
                ],
                "conclusion": "Since the lines intersect at one point, the system has one unique solution."
            }
        },
        {
            "type": "subtitle",
            "data": "Inconsistent Systems"
        },
        {
            "type": "text",
            "data": "An inconsistent system has no solution. In this case, the lines are parallel and never intersect."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Inconsistent System",
                "steps": [
                    {
                        "text": "Consider the system \\(y = 2x + 1\\) and \\(y = 2x - 3\\). These two lines have the same slope but different y-intercepts, meaning they are parallel and will never intersect.",
                        "equation": ""
                    }
                ],
                "conclusion": "Since the lines are parallel, the system has no solution and is considered inconsistent."
            }
        },
        {
            "type": "subtitle",
            "data": "Dependent Systems"
        },
        {
            "type": "text",
            "data": "A dependent system has infinitely many solutions because the equations represent the same line. Every point on the line is a solution."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Dependent System",
                "steps": [
                    {
                        "text": "Consider the system \\(y = 3x - 2\\) and \\(2y = 6x - 4\\). If you simplify the second equation, you will find it is the same as the first equation. This means the two equations represent the same line.",
                        "equation": ""
                    }
                ],
                "conclusion": "Since the two equations represent the same line, the system has infinitely many solutions and is considered dependent."
            }
        },
        {
            "type": "subtitle",
            "data": "Practice Problems"
        },
        {
            "type": "text",
            "data": "Now that you've learned about solving word problems and understanding the consistency of systems, try solving these practice problems."
        }
    ]
},

{
    "title": "Practice",
    "order": 15,
    "practice": [
        {
            "type": "multiple-choice",
            "question": "A store sells pens for $2 each and notebooks for $4 each. If a total of 20 items were sold for $60, how many pens were sold?",
            "options": ["10 pens", "15 pens", "5 pens", "20 pens"],
            "correctAnswer": "10 pens"
        },
        {
            "type": "multiple-choice",
            "question": "What type of system is represented by the equations \\(y = 3x + 2\\) and \\(y = 3x - 4\\)?",
            "options": ["Consistent and independent", "Inconsistent", "Dependent", "Consistent with infinitely many solutions"],
            "correctAnswer": "Inconsistent"
        },
        {
            "type": "multiple-choice",
            "question": "Which of the following is an example of a dependent system?",
            "options": ["The system \\(y = 2x + 1\\) and \\(y = 2x + 3\\)", "The system \\(2x - y = 4\\) and \\(4x - 2y = 8\\)", "The system \\(x + y = 5\\) and \\(x - y = 1\\)", "The system \\(y = x + 1\\) and \\(y = 2x + 1\\)"],
            "correctAnswer": "The system \\(2x - y = 4\\) and \\(4x - 2y = 8\\)"
        },
        {
            "type": "multiple-choice",
            "question": "What does it mean if two lines have the same slope but different y-intercepts?",
            "options": ["They intersect at one point", "They have no solution", "They are dependent", "They have infinitely many solutions"],
            "correctAnswer": "They have no solution"
        },
        {
            "type": "multiple-choice",
            "question": "If two lines are coincident (represent the same line), what can be said about the system of equations?",
            "options": ["It has no solution", "It has one solution", "It has infinitely many solutions", "It is inconsistent"],
            "correctAnswer": "It has infinitely many solutions"
        }
    ]
},

{
    "title": "Solving and Graphing Linear Inequalities",
    "order": 16,
    "content": [
        {
            "type": "subtitle",
            "data": "Introduction to Linear Inequalities"
        },
        {
            "type": "text",
            "data": "Linear inequalities are similar to linear equations, but instead of an equal sign, they involve inequality symbols such as \\(<\\), \\(>\\), \\(\\leq\\), or \\(\\geq\\). Solving these inequalities involves finding the set of values that make the inequality true."
        },
        {
            "type": "subtitle",
            "data": "Solving Linear Inequalities in One Variable"
        },
        {
            "type": "text",
            "data": "To solve a linear inequality in one variable, you follow the same steps as solving an equation, but you need to pay attention to the direction of the inequality sign. If you multiply or divide by a negative number, you must reverse the inequality sign."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Solving a Linear Inequality",
                "steps": [
                    {
                        "text": "Solve the inequality: \\(2x - 5 > 3\\).",
                        "equation": ""
                    },
                    {
                        "text": "Step 1: Add 5 to both sides: \\(2x > 8\\).",
                        "equation": ""
                    },
                    {
                        "text": "Step 2: Divide both sides by 2: \\(x > 4\\).",
                        "equation": ""
                    }
                ],
                "conclusion": "The solution to the inequality is \\(x > 4\\), which means any value greater than 4 will satisfy the inequality."
            }
        },
        {
            "type": "subtitle",
            "data": "Graphing Solutions on a Number Line"
        },
        {
            "type": "text",
            "data": "The solution to a one-variable inequality can be represented on a number line. For \\(x > 4\\), you would draw an open circle at 4 and shade the line to the right of 4 to represent all the values greater than 4."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Graphing the Solution \\(x \\geq 2\\)",
                "steps": [
                    {
                        "text": "Since the inequality is \\(\\geq\\), you will draw a closed circle at 2 and shade the line to the right to represent all values greater than or equal to 2.",
                        "equation": ""
                    }
                ],
                "conclusion": "The graph shows that the solution includes all numbers greater than or equal to 2."
            }
        },
        {
            "type": "subtitle",
            "data": "Solving Two-Variable Inequalities"
        },
        {
            "type": "text",
            "data": "Inequalities with two variables, such as \\(y < 2x + 3\\), involve a boundary line and a shaded region. The boundary line is the line that corresponds to the equation \\(y = 2x + 3\\), and the inequality sign determines which side of the line is shaded."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Solving and Graphing \\(y \\leq x + 1\\)",
                "steps": [
                    {
                        "text": "Step 1: Graph the boundary line \\(y = x + 1\\). Since the inequality is \\(\\leq\\), the boundary line is solid.",
                        "equation": ""
                    },
                    {
                        "text": "Step 2: Determine which side of the line to shade. Choose a test point, such as \\((0, 0)\\).",
                        "equation": ""
                    },
                    {
                        "text": "Step 3: Substitute \\((0, 0)\\) into the inequality: \\(0 \\leq 0 + 1\\), which is true. Therefore, shade the region below the line.",
                        "equation": ""
                    }
                ],
                "conclusion": "The graph shows the solution to the inequality, which includes the line itself and the region below it."
            }
        },
        {
            "type": "subtitle",
            "data": "Boundary Lines for Two-Variable Inequalities"
        },
        {
            "type": "text",
            "data": "When graphing two-variable inequalities, the boundary line can either be solid or dashed. A solid line indicates that points on the line are part of the solution (\\(\\leq\\) or \\(\\geq\\)), while a dashed line indicates that points on the line are not part of the solution (\\(<\\) or \\(>\\))."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Solid vs Dashed Boundary Lines",
                "steps": [
                    {
                        "text": "For the inequality \\(y \\geq 3x - 2\\), graph a solid line for the boundary because points on the line are included in the solution.",
                        "equation": ""
                    },
                    {
                        "text": "For the inequality \\(y < -x + 5\\), graph a dashed line because points on the line are not included in the solution.",
                        "equation": ""
                    }
                ],
                "conclusion": "Remember to use a solid line for \\(\\leq\\) and \\(\\geq\\), and a dashed line for \\(<\\) and \\(>\\)."
            }
        },
        {
            "type": "subtitle",
            "data": "Practice Problems"
        },
        {
            "type": "text",
            "data": "Now that you've learned about solving and graphing linear inequalities, try solving these practice problems."
        }
    ]
},

{
    "title": "Practice",
    "order": 17,
    "practice": [
        {
            "type": "multiple-choice",
            "question": "Solve the inequality: \\(3x - 7 \\leq 5\\).",
            "options": ["\\(x \\leq 4\\)", "\\(x \\geq 4\\)", "\\(x \\leq 6\\)", "\\(x > 4\\)"],
            "correctAnswer": "\\(x \\leq 4\\)"
        },
        {
            "type": "multiple-choice",
            "question": "How would you represent the solution \\(x > 2\\) on a number line?",
            "options": ["Open circle at 2, shade to the right", "Closed circle at 2, shade to the right", "Open circle at 2, shade to the left", "Closed circle at 2, shade to the left"],
            "correctAnswer": "Open circle at 2, shade to the right"
        },
        {
            "type": "multiple-choice",
            "question": "Which of the following inequalities requires a solid boundary line when graphed?",
            "options": ["\\(y > 2x - 1\\)", "\\(y \\leq 3x + 2\\)", "\\(y < -x + 4\\)", "\\(y > 5x - 3\\)"],
            "correctAnswer": "\\(y \\leq 3x + 2\\)"
        },
        {
            "type": "multiple-choice",
            "question": "For the inequality \\(y < 2x - 3\\), which region of the graph is shaded?",
            "options": ["Above the line", "Below the line", "On the line", "None of these"],
            "correctAnswer": "Below the line"
        },
        {
            "type": "multiple-choice",
            "question": "If you solve the inequality \\(2x + 4 > 10\\), what is the solution?",
            "options": ["\\(x > 3\\)", "\\(x < 3\\)", "\\(x \\leq 3\\)", "\\(x \\geq 3\\)"],
            "correctAnswer": "\\(x > 3\\)"
        }
    ]
},

{
    "title": "Systems of Inequalities and Real-World Applications",
    "order": 18,
    "content": [
        {
            "type": "subtitle",
            "data": "Introduction to Systems of Inequalities"
        },
        {
            "type": "text",
            "data": "A system of inequalities consists of two or more inequalities that are solved simultaneously. The solution to the system is the region where the graphs of all inequalities overlap, known as the feasible region."
        },
        {
            "type": "subtitle",
            "data": "Solving and Graphing Systems of Inequalities"
        },
        {
            "type": "text",
            "data": "To solve a system of inequalities, graph each inequality on the coordinate plane and identify the region that satisfies all inequalities. This overlapping region is the solution to the system."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Solving a System of Inequalities",
                "steps": [
                    {
                        "text": "Step 1: Graph the first inequality \\(y \\leq 2x + 3\\). Since it’s \\(\\leq\\), draw a solid line and shade the region below the line.",
                        "equation": ""
                    },
                    {
                        "text": "Step 2: Graph the second inequality \\(y > -x + 1\\). Since it’s \\(>\\), draw a dashed line and shade the region above the line.",
                        "equation": ""
                    },
                    {
                        "text": "Step 3: Identify the region where the shaded areas overlap. This is the feasible region that satisfies both inequalities.",
                        "equation": ""
                    }
                ],
                "conclusion": "The solution to the system is the overlapping shaded region on the graph, known as the feasible region."
            }
        },
        {
            "type": "subtitle",
            "data": "Feasible Regions"
        },
        {
            "type": "text",
            "data": "The feasible region is the area on the graph where all inequalities in a system are satisfied. This region may be bounded or unbounded, depending on the constraints provided by the inequalities."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Identifying the Feasible Region",
                "steps": [
                    {
                        "text": "For the system of inequalities \\(y \\leq 3x + 1\\) and \\(y \\geq -2x - 2\\), graph both inequalities. The feasible region will be the area where the shaded regions overlap.",
                        "equation": ""
                    }
                ],
                "conclusion": "The feasible region is the set of points that satisfy both inequalities, representing the solution to the system."
            }
        },
        {
            "type": "subtitle",
            "data": "Real-World Applications of Systems of Inequalities"
        },
        {
            "type": "text",
            "data": "Systems of inequalities are used to model real-world situations where multiple constraints are present. These situations often involve maximizing or minimizing values, such as profit or cost, subject to constraints like budget or resources."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Applying Systems of Inequalities in Real-World Scenarios",
                "steps": [
                    {
                        "text": "A company manufactures two types of products, A and B. Each product requires different amounts of materials. The company has a maximum of 100 units of material available, and it needs to manufacture at least 10 units of product A. Represent the constraints with inequalities.",
                        "equation": ""
                    },
                    {
                        "text": "Step 1: Let \\(x\\) represent the number of units of product A and \\(y\\) represent the number of units of product B. The inequality \\(x + y \\leq 100\\) represents the material constraint.",
                        "equation": ""
                    },
                    {
                        "text": "Step 2: The inequality \\(x \\geq 10\\) represents the requirement to produce at least 10 units of product A.",
                        "equation": ""
                    },
                    {
                        "text": "Step 3: Graph both inequalities and identify the feasible region that satisfies both constraints.",
                        "equation": ""
                    }
                ],
                "conclusion": "The feasible region shows the combinations of products A and B the company can produce while meeting the material and production constraints."
            }
        },
        {
            "type": "subtitle",
            "data": "Boundary Lines and Shading for Systems of Inequalities"
        },
        {
            "type": "text",
            "data": "Just like individual inequalities, the boundary lines in systems of inequalities can be solid or dashed, depending on whether the inequality includes equal to (\\(\\leq\\) or \\(\\geq\\)) or does not include equal to (\\(<\\) or \\(>\\)). Shading the appropriate regions helps visualize the solution set."
        },
        {
            "type": "steps",
            "data": {
                "title": "Example: Boundary Lines for Systems of Inequalities",
                "steps": [
                    {
                        "text": "For the system \\(y < 2x + 1\\) and \\(y \\geq -x - 2\\), graph a dashed line for \\(y < 2x + 1\\) and a solid line for \\(y \\geq -x - 2\\).",
                        "equation": ""
                    },
                    {
                        "text": "Shade below the dashed line and above the solid line. The solution is the region where the shaded areas overlap.",
                        "equation": ""
                    }
                ],
                "conclusion": "Use solid lines for \\(\\leq\\) and \\(\\geq\\) inequalities and dashed lines for \\(<\\) and \\(>\\) inequalities. The solution lies in the overlapping region."
            }
        },
        {
            "type": "subtitle",
            "data": "Practice Problems"
        },
        {
            "type": "text",
            "data": "Now that you've learned about solving and graphing systems of inequalities, try solving these practice problems."
        }
    ]
},

{
    "title": "Practice",
    "order": 19,
    "practice": [
        {
            "type": "multiple-choice",
            "question": "Which region represents the solution to the system \\(y \\leq 2x + 3\\) and \\(y > -x + 1\\)?",
            "options": ["Above both lines", "Below both lines", "The region where the shaded areas overlap", "The region where the lines intersect"],
            "correctAnswer": "The region where the shaded areas overlap"
        },
        {
            "type": "multiple-choice",
            "question": "What type of boundary line should be used for the inequality \\(y \\geq 3x - 2\\)?",
            "options": ["Dashed line", "Solid line", "No line", "Double line"],
            "correctAnswer": "Solid line"
        },
        {
            "type": "multiple-choice",
            "question": "How do you determine which region to shade when graphing an inequality like \\(y < x - 4\\)?",
            "options": ["Shade above the line", "Shade below the line", "Shade on the line", "Shade neither side"],
            "correctAnswer": "Shade below the line"
        },
        {
            "type": "multiple-choice",
            "question": "In a real-world scenario, what does the feasible region represent when solving a system of inequalities?",
            "options": ["The set of all possible solutions", "The minimum value of the system", "The intersection of two lines", "The area above the boundary lines"],
            "correctAnswer": "The set of all possible solutions"
        },
        {
            "type": "multiple-choice",
            "question": "If a company is limited by both budget and production constraints, how could these be represented mathematically?",
            "options": ["As a system of equations", "As a system of inequalities", "As one inequality", "As a linear equation"],
            "correctAnswer": "As a system of inequalities"
        }
    ]
},









  
  
  
  
              
              
              
              
          
          

    
      ]
    },
  











    // Chapter 2: CSS Basics
    {
      title: "Problem solving and Data Analysis",
      order: 2,
      locked: true,
      unlockDate: "2024-11-04",
      lessons: [
        {
          title: "Introduction to CSS",
          order: 1,
          content: [
            {
              type: "text",
              data: "CSS is used to style HTML elements."
            },
            {
              type: "image",
              data: "https://example.com/css-introduction-image.jpg"
            },
            {
              type: "multiple-choice",
              data: {
                question: "What does CSS stand for?",
                options: ["Cascading Style Sheets", "Creative Style Sheets", "Colorful Style Sheets"],
                correctAnswer: "Cascading Style Sheets"
              }
            }
          ]
        }
      ]
    },

    {
      title: "Advanced Math",
      order: 3,
      locked: true,
      unlockDate: "2024-11-11",
      lessons: []
    },
    {
      title: "Advanced Math",
      order: 4,
      locked: true,
      unlockDate: "2024-11-18",
      lessons: []
    },
    {
      title: "Geometry and Trigonometry",
      order: 5,
      locked: true,
      unlockDate: "2024-11-25",
      lessons: []
    },
  ];



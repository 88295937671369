import { useState, useEffect } from 'react';
import { auth } from '../firebase';  // Adjust the import path according to your firebase configuration

const useAuth = () => {
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);  // Track loading state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid);
      } else {
        console.error('No authenticated user found');
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  return { userId, loading };
};

export default useAuth;

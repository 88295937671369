// src/components/Subscribe.js

import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useAuth } from '../contexts/AuthContext'; // Assuming you have an Auth Context
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase'; // Your Firebase initialization

const Subscribe = () => {
  const stripe = useStripe();
  const { currentUser } = useAuth();

  const handleSubscribe = async () => {
    if (!stripe || !currentUser) {
      return;
    }

    try {
      // Call Firebase function to create Checkout Session
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      const { data } = await createCheckoutSession();

      // Redirect to Stripe Checkout
      const { sessionId } = data;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error('Stripe Checkout Error:', error);
      }
    } catch (error) {
      console.error('Error subscribing:', error);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Upgrade to Premium</h2>
      <p className="mb-6">Get access to live classes and 200 AI queries per month for just $20/month.</p>
      <button
        onClick={handleSubscribe}
        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
      >
        Upgrade Now
      </button>
    </div>
  );
};

export default Subscribe;

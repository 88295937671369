// PracticeScreen.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase'; // Adjust the import according to your firebase setup
import { useAuth } from '../contexts/AuthContext'; // Get currentUser
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import { storeCompletedLesson } from '../firebase/firebaseUtils'; // Import the function
import { ArrowLeft, CheckCircle, XCircle, ChevronRight, Trophy } from 'lucide-react';

const PracticeScreen = () => {
  const { courseId, chapterId, lessonId } = useParams(); // Get route parameters
  const { currentUser } = useAuth(); // Get user information
  const navigate = useNavigate(); // For navigation
  const [practiceQuestions, setPracticeQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userAnswer, setUserAnswer] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track current question
  const [correctAnswered, setCorrectAnswered] = useState(false); // Track if the correct answer is selected
  const [progress, setProgress] = useState(0); // Track progress
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null); // Track selected option
  const [isChecked, setIsChecked] = useState(false); // Track if the answer has been checked
  const [isFinished, setIsFinished] = useState(false); // Track if user has finished all questions

  // State variables for AI interaction
  const [aiExplanation, setAiExplanation] = useState(''); // Store AI explanation
  const [isAiLoading, setIsAiLoading] = useState(false); // Track AI loading state

  useEffect(() => {
    const loadPracticeQuestions = async () => {
      if (!lessonId) {
        console.warn('lessonId is missing');
        setLoading(false);
        return;
      }

      try {
        const lessonRef = doc(
          firestore,
          'courses',
          courseId,
          'chapters',
          chapterId,
          'lessons',
          lessonId
        );
        const lessonDoc = await getDoc(lessonRef);

        if (lessonDoc.exists()) {
          const lessonData = lessonDoc.data();
          setPracticeQuestions(lessonData.practice || []);
        } else {
          console.error('Lesson not found');
        }
      } catch (error) {
        console.error('Error fetching practice questions:', error);
        toast.error('Failed to load practice questions.');
      } finally {
        setLoading(false);
      }
    };

    loadPracticeQuestions();
  }, [courseId, chapterId, lessonId]);

  const handleAnswer = (index) => {
    setSelectedOptionIndex(index); // Set the selected option index
    setIsChecked(false); // Reset check state when user changes selection
    setUserAnswer(null); // Reset previous answer feedback
  };

  const handleCheckAnswer = () => {
    if (selectedOptionIndex === null) {
      toast.warn('Please select an option before checking.');
      return;
    }

    const selectedOption =
      practiceQuestions[currentQuestionIndex].options[selectedOptionIndex];
    const correctAnswer = practiceQuestions[currentQuestionIndex].correctAnswer;

    if (selectedOption === correctAnswer) {
      setUserAnswer('Correct!');
      setCorrectAnswered(true); // Enable continue button
    } else {
      setUserAnswer('Incorrect, try again.');
      setCorrectAnswered(false); // Disable continue button
    }
    setIsChecked(true); // Mark as checked after checking
  };

  const handleNextQuestion = () => {
    if (correctAnswered && currentQuestionIndex < practiceQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setUserAnswer(null);
      setCorrectAnswered(false);
      setSelectedOptionIndex(null); // Reset the selected option index
      setIsChecked(false); // Reset check state
      setAiExplanation(''); // Reset AI explanation
      setProgress((currentQuestionIndex + 1) / practiceQuestions.length); // Update progress
    } else if (
      correctAnswered &&
      currentQuestionIndex === practiceQuestions.length - 1
    ) {
      // Mark as finished once all questions are answered
      setIsFinished(true);
      setProgress(1); // Complete the progress bar
      handleCompleteLesson(); // Call the function to mark lesson as completed
    }
  };

  // Function to handle the "Ask AI" button click
  const handleAskAi = async () => {
    setIsAiLoading(true);

    const question = practiceQuestions[currentQuestionIndex].question;
    const options = practiceQuestions[currentQuestionIndex].options;

    let problemText = `${question}\n\nOptions:\n`;

    options.forEach((option, index) => {
      problemText += `${String.fromCharCode(65 + index)}. ${option}\n`;
    });

    try {
      const response = await fetch(
        'https://us-central1-avantdemy2.cloudfunctions.net/solveProblem',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ problemText }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setAiExplanation(data.explanation);
      } else {
        throw new Error(data.error || 'Failed to fetch AI explanation.');
      }
    } catch (error) {
      console.error('Error fetching AI explanation:', error);
      toast.error('Failed to fetch AI explanation. Please try again.');
    } finally {
      setIsAiLoading(false);
    }
  };

  // Function to handle marking the lesson as completed
  const handleCompleteLesson = async () => {
    try {
      if (!currentUser) {
        throw new Error('User is not authenticated');
      }
      await storeCompletedLesson(currentUser.uid, courseId, lessonId);
      // Optionally, display a success message
      toast.success(`Lesson ${lessonId} marked as completed!`);
    } catch (error) {
      console.error('Error marking lesson as completed:', error);
      toast.error('Failed to mark lesson as completed. Please try again.');
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous screen
  };

  // Helper function to render text with line breaks and math
  const renderTextWithMath = (text) => {
    const parts = text.split(/\\\((.*?)\\\)/g); // Matches \( ... \)
    return parts.map((part, idx) => {
      if (idx % 2 === 1) {
        // If we're inside LaTeX delimiters
        const latex = part.replace(/\\\\/g, '\\');
        return <InlineMath key={idx} math={latex} />;
      } else {
        // Regular text, replace \n with <br />
        return (
          <span key={idx}>
            {part.split('\n').map((line, lineIndex) => (
              <React.Fragment key={lineIndex}>
                {line}
                {lineIndex < part.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </span>
        );
      }
    });
  };

  if (isFinished) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
        <div className="max-w-2xl mx-auto pt-20 px-4">
          <div className="bg-white rounded-2xl shadow-xl p-8 text-center">
            <div className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <Trophy className="w-10 h-10 text-green-600" />
            </div>
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              Congratulations!
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              You've successfully completed all practice questions.
            </p>
            <button
              onClick={handleGoBack}
              className="inline-flex items-center px-6 py-3 text-white bg-blue-600 rounded-xl hover:bg-blue-700 transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Return to Lessons
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <main className="max-w-3xl mx-auto px-4 py-8">
        {loading ? (
          <div className="flex items-center justify-center min-h-[60vh]">
            <div className="animate-pulse space-y-4">
              <div className="h-4 w-48 bg-gray-200 rounded"></div>
              <div className="h-12 w-96 bg-gray-200 rounded"></div>
              <div className="space-y-2">
                <div className="h-8 w-full bg-gray-200 rounded"></div>
                <div className="h-8 w-full bg-gray-200 rounded"></div>
                <div className="h-8 w-full bg-gray-200 rounded"></div>
              </div>
            </div>
          </div>
        ) : practiceQuestions.length > 0 ? (
          <div className="space-y-6">
            {/* Progress Bar */}
            <div className="flex items-center mb-6">
              {/* 'X' Button */}
              <button
                onClick={handleGoBack}
                className="mr-4 text-gray-400 hover:text-gray-600 transition-colors duration-200"
                aria-label="Close Practice Session"
              >
                {/* Standalone X Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              {/* Progress Bar */}
              <div className="w-full bg-gray-300 rounded-full h-4">
                <div
                  className="bg-green-500 h-4 rounded-full transition-all duration-300 ease-out"
                  style={{ width: `${progress * 100}%` }}
                ></div>
              </div>
            </div>

            {/* Question Card */}
            <div className="bg-white rounded-2xl shadow-lg p-6 transition-all duration-300">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">
                {renderTextWithMath(
                  practiceQuestions[currentQuestionIndex].question
                )}
              </h2>

              {/* Options */}
              <div className="space-y-3">
                {practiceQuestions[currentQuestionIndex].options.map(
                  (option, idx) => (
                    <button
                      key={idx}
                      onClick={() => handleAnswer(idx)}
                      disabled={correctAnswered}
                      className={`
                        w-full text-left p-4 rounded-xl transition-all duration-200
                        flex items-center justify-between gap-4
                        ${
                          selectedOptionIndex === idx
                            ? isChecked
                              ? userAnswer === 'Correct!'
                                ? 'bg-green-100 border-2 border-green-500 text-green-900'
                                : 'bg-red-100 border-2 border-red-500 text-red-900'
                              : 'bg-blue-100 border-2 border-blue-500 text-blue-900'
                            : 'bg-gray-50 border-2 border-gray-200 text-gray-700 hover:bg-gray-100 hover:border-gray-300'
                        }
                        disabled:cursor-not-allowed
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                      `}
                    >
                      <span className="flex-1">
                        {renderTextWithMath(option)}
                      </span>
                      {isChecked && selectedOptionIndex === idx && (
                        userAnswer === 'Correct!' ? (
                          <CheckCircle className="w-6 h-6 text-green-600" />
                        ) : (
                          <XCircle className="w-6 h-6 text-red-600" />
                        )
                      )}
                    </button>
                  )
                )}
              </div>

              {/* Feedback Message */}
              {userAnswer && (
                <div
                  className={`
                    mt-6 p-4 rounded-xl flex items-center gap-3
                    ${
                      userAnswer === 'Correct!'
                        ? 'bg-green-50 text-green-900'
                        : 'bg-red-50 text-red-900'
                    }
                  `}
                >
                  {userAnswer === 'Correct!' ? (
                    <CheckCircle className="w-5 h-5 text-green-600" />
                  ) : (
                    <XCircle className="w-5 h-5 text-red-600" />
                  )}
                  <p className="font-medium">{userAnswer}</p>
                </div>
              )}

              {/* AI Explanation */}
              {aiExplanation && (
                <div className="mt-6 p-4 rounded-xl bg-blue-50 text-blue-900 relative">
                  <button
                    onClick={() => setAiExplanation('')}
                    className="absolute top-2 right-2 text-blue-600 hover:text-blue-800"
                  >
                    &times;
                  </button>
                  <h3 className="text-lg font-semibold mb-2">AI Explanation:</h3>
                  <p>{aiExplanation}</p>
                </div>
              )}
            </div>

            {/* Action Buttons */}
            <div className="flex justify-between items-center pt-4">
              <button
                onClick={handleGoBack}
                className="inline-flex items-center px-4 py-2 text-gray-700 bg-gray-100 rounded-xl hover:bg-gray-200 transition-all duration-200"
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                Go Back
              </button>

              <div className="flex space-x-4">
                {correctAnswered ? (
                  <button
                    onClick={handleNextQuestion}
                    className="inline-flex items-center px-6 py-3 rounded-xl text-white bg-blue-600 hover:bg-blue-700 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Continue
                    <ChevronRight className="w-5 h-5 ml-2" />
                  </button>
                ) : (
                  <>
                    <button
                      onClick={handleAskAi}
                      className="inline-flex items-center px-4 py-2 rounded-xl text-white bg-purple-600 hover:bg-purple-700 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      disabled={isAiLoading}
                    >
                      {isAiLoading ? 'Thinking...' : 'Ask AI'}
                    </button>
                    <button
                      onClick={handleCheckAnswer}
                      disabled={selectedOptionIndex === null}
                      className={`inline-flex items-center px-6 py-3 rounded-xl transition-all duration-200
                        ${
                          selectedOptionIndex === null
                            ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                            : 'bg-green-600 text-white hover:bg-green-700'
                        }
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
                      `}
                    >
                      Check Answer
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center min-h-[60vh] text-center">
            <div className="bg-gray-100 p-4 rounded-full mb-4">
              <XCircle className="w-8 h-8 text-gray-400" />
            </div>
            <p className="text-lg text-gray-600">No practice questions available.</p>
            <button
              onClick={handleGoBack}
              className="mt-4 inline-flex items-center px-4 py-2 text-blue-600 hover:text-blue-700"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Return to Lessons
            </button>
          </div>
        )}
      </main>
    </div>
  );
};

export default PracticeScreen;

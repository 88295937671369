// src/components/LandingPage.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Home from '../screens/Home';

function LandingPage() {
  const { currentUser } = useAuth();

  if (currentUser) {
    // User is logged in, render the Home component
    return <Home />;
  } else {
    // User is not logged in, redirect to /about
    return <Navigate to="/about" replace />;
  }
}

export default LandingPage;

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Lesson from './Lesson';
import {getCompletedLessons} from '../firebase/firebaseUtils';
import useAuth from '../hooks/useAuth';


const LessonContainer = ({ lessons, courseId, chapterId }) => {
    const [completedLessonIds, setCompletedLessonIds] = useState([]);

    const { userId } = useAuth(); 
  
    useEffect(() => {
      if (userId && courseId) {
        // Fetch completed lessons from Firebase when the component mounts
        const fetchCompletedLessons = async () => {
          const completedLessons = await getCompletedLessons(userId, courseId);
          setCompletedLessonIds(completedLessons);
        };
        fetchCompletedLessons();
      }
    }, [userId, courseId]);
  
    return (
      <div className="p-4 space-y-4 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mx-auto">
        {lessons.map((lesson) => (
          <Lesson
            key={lesson.id}
            lesson={lesson}
            courseId={courseId}
            chapterId={chapterId}
            isCompleted={completedLessonIds.includes(lesson.id) || false} // Pass false if not yet determined
          />
        ))}
      </div>
    );
  };
  
  LessonContainer.propTypes = {
    lessons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.array,
        practice: PropTypes.array,
      })
    ).isRequired,
    userId: PropTypes.string.isRequired, // Needed to fetch the user-specific completed lessons
    courseId: PropTypes.string.isRequired,
    chapterId: PropTypes.string.isRequired,
  };
  
  export default LessonContainer;
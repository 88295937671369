import React from 'react';
import Navbar from '../components/Navbar';

const ComingSoon = () => {
  return (
   <div>
     <Navbar />
    <div className="flex items-center justify-center h-screen ">
      <h1 className="text-center text-xl font-semibold text-gray-800">
        Live Class links will be added here. First class on 11/01/24. Reminders will always be sent.
      </h1>
    </div>
   </div>
  );
};

export default ComingSoon;

// src/components/Navbar.js

import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../firebase';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // For the profile dropdown
  const [fullName, setFullName] = useState('');
  const navigate = useNavigate();

  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchUserName = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(firestore, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setFullName(userData.fullName || '');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserName();
  }, [currentUser]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log('User signed out');
      navigate('/'); // Navigate to home page after sign out
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getInitials = (name) => {
    if (!name) return '';
    const nameParts = name.trim().split(' ');
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    } else {
      return (
        nameParts[0].charAt(0).toUpperCase() +
        nameParts[nameParts.length - 1].charAt(0).toUpperCase()
      );
    }
  };

  // Function to handle navigation to UpgradeScreen
  const goToUpgradeScreen = () => {
    navigate('/upgrade');
  };

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <a href="/" className="text-2xl text-black-800">
              Avantdemy
            </a>
          </div>

          {/* Desktop Navigation Links */}
          <div className="hidden md:flex md:space-x-8">
            <a href="/" className="text-gray-700 hover:text-blue-800 font-sans">
              Home
            </a>
            <a href="/" className="text-gray-700 hover:text-blue-800 font-sans">
              Explore
            </a>
            <a href="/comingsoon" className="text-gray-700 hover:text-blue-800 font-sans">
              Live 
            </a>
            <a href="/" className="text-gray-700 hover:text-blue-800 font-sans">
              Research
            </a>
          </div>

          {/* Right Side Buttons: Plus Button and User Profile */}
          <div className="hidden md:flex items-center space-x-4">
            {/* Plus Button for UpgradeScreen */}
            <button
              onClick={goToUpgradeScreen}
              className="inline-flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-600 text-white font-medium rounded-lg shadow-lg hover:shadow-xl hover:from-indigo-600 hover:to-purple-700 transform hover:-translate-y-0.5 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
            >
              Plus
            </button>

            {/* User Profile Dropdown */}
            <div className="relative">
  <button
    onClick={toggleDropdown}
    className="flex items-center focus:outline-none"
  >
    <div className="w-10 h-10 rounded-full bg-[#1E3A8A] flex items-center justify-center text-white text-sm font-semibold">
      {getInitials(fullName)}
    </div>
  </button>
  {dropdownOpen && (
    <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg py-1 z-50">
      <button
        onClick={handleSignOut}
        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
      >
        Log Out
      </button>
    </div>
  )}
</div>

          </div>

          {/* Mobile Menu Button */}
          <div className="absolute right-0 flex items-center md:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="text-gray-700 hover:text-blue-800 focus:outline-none"
            >
              {/* Icon for menu (hamburger/cross) */}
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                {mobileMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        {mobileMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <a
                href="/"
                className="block text-gray-700 hover:text-blue-800 font-sans"
              >
                Home
              </a>
              <a
                href="#"
                className="block text-gray-700 hover:text-blue-800 font-sans"
              >
                Explore
              </a>
              <a
                href="#"
                className="block text-gray-700 hover:text-blue-800 font-sans"
              >
                Jobs
              </a>
              <a
                href="#"
                className="block text-gray-700 hover:text-blue-800 font-sans"
              >
                Research
              </a>
              {/* User Profile in Mobile Menu */}
              <div className="border-t border-gray-200 mt-2 pt-2">
                <div className="flex items-center px-4">
                  <div className="w-8 h-8 rounded-full bg-gray-500 flex items-center justify-center text-white text-sm font-semibold">
                    {getInitials(fullName)}
                  </div>
                  <span className="ml-2 text-gray-700">{fullName}</span>
                </div>
                <button
                  onClick={handleSignOut}
                  className="block w-full text-left text-gray-700 hover:text-blue-800 bg-gray-100 border border-gray-300 rounded-md px-4 py-2 text-sm font-sans mt-2"
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

import React from 'react';

const AboutScreen = () => {
  return (
    <div className="overflow-x-hidden bg-white">
      <header className="relative py-4 md:py-6">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between">
            <div className="flex-shrink-0">
              <a href="/" className="text-2xl font-bold text-black-800">
                Avantdemy
              </a>
            </div>
            <div className="flex lg:hidden">
              <button type="button" className="text-gray-900">
                <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>

            <div className="hidden lg:absolute lg:inset-y-0 lg:flex lg:items-center lg:justify-center lg:space-x-12 lg:-translate-x-1/2 lg:left-1/2">
              <a href="/login" className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">Home</a>
              <a href="/login" className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">Explore</a>
              <a href="/login" className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">AI Tools</a>
            </div>

            <div className="hidden lg:flex lg:items-center lg:justify-center lg:space-x-10">
              <a href="/login" className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                Login
              </a>
              <a
                href="/signup"
                className="px-5 py-2 text-base font-semibold leading-7 text-gray-900 transition-all duration-200 bg-transparent border border-gray-900 rounded-xl font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white"
                role="button"
              >
                Join community
              </a>
            </div>
          </div>
        </div>
      </header>

      <section className="relative py-12 sm:py-16 lg:pt-20 xl:pb-0">
        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-3xl mx-auto text-center">
            <p className="inline-flex px-4 py-2 text-base text-gray-900 border border-gray-200 rounded-full font-pj">AI Tools, School, Careers, and Business</p>

            <h1 className="mt-5 text-4xl font-bold leading-tight text-gray-900 sm:text-5xl sm:leading-tight lg:text-6xl lg:leading-tight font-pj">
              AI Powered Advancement Platform to get into your
              <span className="block whitespace-nowrap"> dream college</span>
            </h1>

            <p className="max-w-md mx-auto mt-6 text-base leading-7 text-gray-600 font-inter">AI Powered Learning to Achieve a 1400+ Guarentee on the SAT or your Money Back Stress Free (blazing fast),</p>

            <div className="relative inline-flex mt-10 group">
              <div className="absolute transition-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>

              <a href="/signup" className="relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900" role="button">
                Try Today for Free
              </a>
            </div>
          </div>
        </div>
      </section>
      <hr className="mt-32 mb-32 border-gray-300" />

      {/* New Section for tinygrad text */}
      <section className="max-w-3xl mx-auto mt-10 mb-32 px-4">



      <h2 
  className="text-2xl font-bold mb-4 text-xl" 
  style={{ 
    fontFamily: "'Press Start 2P', sans-serif", 
    lineHeight: 'normal', 
    letterSpacing: '0.05em' // Adjust this value as needed 
  }}
>
  welcome advancers,
</h2>
        
        <p className="text-base leading-7 mb-2">Avantdemy is a group of researchers and engineers at Emory University bringing you the highest quality tools, methods, and resources so you can advance to your dream college.</p>
        <p className="text-base leading-7 mb-2">We streamline this whole process and make it simple and pain less as possible. here's how:</p>

        <h2 
  className="text-xl font-bold mb-4 text-xl" 
  style={{ lineHeight: 'normal', letterSpacing: '0.05em' }}>
  Making the SAT Easy
</h2>
<p className="text-base leading-7 mb-2">
  We offer a unique hybrid approach to SAT prep, blending the power of AI with guidance from human experts to help you achieve your dream score. We guarantee a 1400+ score or your money back (with Avantdemy+). 
  {/* <a href="#">Learn more about our guarantee here.</a> */}
</p>

<h2 className="text-xl font-bold mb-4" style={{ lineHeight: 'normal', letterSpacing: '0.05em' }}>Bite-Sized Lessons</h2>

<p className="text-base leading-7 mb-2">
  We know you're busy—we’ve been there too. That’s why our lessons are designed to be bite-sized, taking just around 15 minutes out of your day.
</p>


        <h2 className="text-xl font-bold mb-4" style={{ lineHeight: 'normal', letterSpacing: '0.05em' }}>All-In-One SAT Prep Solution</h2>
<p className="text-base leading-7 mb-2">
   Unlock the full potential of your SAT prep with every resource, strategy, and guide you’ll need to reach your target score. 
   With expertly curated content and smart learning paths, we make sure you're covered from start to finish, building skills and confidence along the way.
</p>

<h2 className="text-xl font-bold mb-4" style={{ lineHeight: 'normal', letterSpacing: '0.05em' }}>AI-Powered Personalization</h2>
<p className="text-base leading-7 mb-2">
Our AI is designed to guide you through any challenges, providing clear explanations and real-time support to keep you on track and accelerate your progress toward your goals.
</p>

      </section>
    </div>
  );
};

export default AboutScreen;

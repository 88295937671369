import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CheckCircle, ChevronRight, BookOpen } from 'lucide-react';

const Lesson = ({ lesson, courseId, chapterId, isCompleted }) => {
  const navigate = useNavigate();

  const handleLessonClick = () => {
    if (lesson.content && lesson.content.length > 0) {
      navigate(`/lesson-content/${courseId}/${chapterId}/${lesson.id}`);
    } else {
      handlePracticeClick();
    }
  };

  const handlePracticeClick = () => {
    if (lesson.practice && lesson.practice.length > 0) {
      navigate(`/practice/${courseId}/${chapterId}/${lesson.id}`);
    }
  };

  return (
    <div className="group relative my-3">
      <button
        onClick={handleLessonClick}
        className={`
          w-full
          p-4
          rounded-xl
          bg-white
          border
          transition-all
          duration-200
          ease-in-out
          flex
          items-center
          justify-between
          gap-4
          hover:scale-[1.01]
          focus:outline-none
          focus:ring-2
          focus:ring-blue-500
          focus:ring-offset-2
          ${
            isCompleted
              ? 'border-blue-200 hover:border-blue-300 bg-blue-50/50'
              : 'border-gray-200 hover:border-gray-300 hover:bg-gray-50/50'
          }
        `}
        aria-label={`Navigate to lesson ${lesson.title}`}
      >
        <div className="flex items-center gap-3 flex-1">
          <div className={`
            flex-shrink-0
            w-10
            h-10
            rounded-lg
            flex
            items-center
            justify-center
            ${isCompleted ? 'bg-blue-100' : 'bg-gray-100'}
          `}>
            {isCompleted ? (
              <CheckCircle className="w-5 h-5 text-blue-500" />
            ) : (
              <BookOpen className="w-5 h-5 text-gray-500" />
            )}
          </div>
          
          <div className="flex-1 text-left">
            <h2 className={`
              font-medium
              transition-colors
              duration-200
              ${isCompleted ? 'text-blue-900' : 'text-gray-900'}
            `}>
              {lesson.title}
            </h2>
            {lesson.practice && lesson.practice.length > 0 && (
              <p className="text-sm text-gray-500 mt-1">
                Includes practice exercises
              </p>
            )}
          </div>
        </div>

        <ChevronRight className={`
          w-5
          h-5
          transition-all
          duration-200
          ${isCompleted ? 'text-blue-500' : 'text-gray-400'}
          group-hover:translate-x-1
        `} />
      </button>

      {isCompleted && (
        <div className="absolute inset-0 -z-10 rounded-xl bg-blue-500/5 blur-sm transition-opacity duration-200 opacity-0 group-hover:opacity-100" />
      )}
    </div>
  );
};

Lesson.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.array,
    practice: PropTypes.array,
  }).isRequired,
  courseId: PropTypes.string.isRequired,
  chapterId: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};

export default Lesson;
// GraphComponent.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LinearScale, CategoryScale, PointElement, LineElement, Tooltip } from 'chart.js';
import { evaluate } from 'mathjs';

// Register the necessary Chart.js components
Chart.register(LinearScale, CategoryScale, PointElement, LineElement, Tooltip);

const generateLinearData = (equation, xRange = [-10, 10], step = 1) => {
  const xValues = [];
  const yValues = [];

  for (let x = xRange[0]; x <= xRange[1]; x += step) {
    xValues.push(x);
    let y;
    try {
      y = evaluate(equation, { x });
    } catch (error) {
      console.error(`Error evaluating equation "${equation}" with x=${x}:`, error);
      y = null;
    }
    yValues.push(y);
  }

  return {
    labels: xValues,
    datasets: [
      {
        label: '', // Leave label empty
        data: yValues,
        fill: false,
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.1,
      },
    ],
  };
};

const GraphComponent = ({ equation, xRange = [-10, 10], step = 1 }) => {
  if (!equation) {
    return <div>Error: Equation is undefined.</div>;
  }

  const graphData = generateLinearData(equation, xRange, step);

  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: { display: true, text: 'x' },
      },
      y: {
        beginAtZero: false,
        title: { display: true, text: 'y' },
      },
    },
    plugins: {
      legend: { display: false }, // Hide legend
      title: { display: false },  // Hide title
    },
  };

  return (
    <div className="my-4">
      <Line data={graphData} options={options} />
    </div>
  );
};

export default GraphComponent;

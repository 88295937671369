// src/screens/ChaptersScreen.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase'; // Adjust based on your Firebase setup
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChapterComponent from '../components/ChapterComponent'; // Import the updated component

const ChaptersScreen = () => {
  const { courseId } = useParams(); // Get courseId from route parameters
  const navigate = useNavigate(); // For navigation
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChapters = async () => {
      if (!courseId) {
        toast.error('Course ID is missing.');
        setLoading(false);
        return;
      }

      try {
        const chaptersRef = collection(firestore, 'courses', courseId, 'chapters');
        const chaptersSnapshot = await getDocs(chaptersRef);

        const chaptersList = chaptersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Log chapters to verify 'locked' and 'unlockDate' fields
        console.log('Fetched Chapters:', chaptersList);

        // Sort chapters by order
        const sortedChapters = chaptersList.sort((a, b) => a.order - b.order);
        setChapters(sortedChapters);
      } catch (error) {
        console.error('Error fetching chapters:', error);
        toast.error('Failed to load chapters. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchChapters();
  }, [courseId]);

  const handleChapterClick = (chapterId, isLocked) => {
    if (!isLocked) {
      navigate(`/lessons/${courseId}/${chapterId}`);
    } else {
      toast.info('This chapter is locked.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      {/* Toast Notifications */}
      <ToastContainer position="top-right" autoClose={3000} />

      {/* Main Content */}
      <main className="flex-grow container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">Chapters</h1>

        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-lg text-gray-700">Loading chapters...</p>
          </div>
        ) : chapters.length > 0 ? (
          <div className="space-y-4">
            {chapters.map((chapter, index) => (
              <ChapterComponent
                key={chapter.id}
                title={chapter.title}
                index={index}
                locked={chapter.locked} // Ensure 'locked' is a boolean
                unlockDate={chapter.unlockDate} // Pass unlockDate to ChapterComponent
                onClick={() => handleChapterClick(chapter.id, chapter.locked)}
              />
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-lg text-gray-700">No chapters available for this course.</p>
          </div>
        )}
      </main>
    </div>
  );
};

export default ChaptersScreen;

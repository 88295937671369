import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import { storeCompletedLesson } from '../firebase/firebaseUtils';
import { useAuth } from '../contexts/AuthContext';
import Navbar from '../components/Navbar';
import GraphComponent from '../components/GraphComponent';

const LessonContentScreen = () => {
  const { courseId, chapterId, lessonId } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [lessonContent, setLessonContent] = useState(null);
  const [lessonTitle, setLessonTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [userAnswer, setUserAnswer] = useState(null);

  // AI interaction state variables
  const [highlightedText, setHighlightedText] = useState('');
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
  const [showExplainButton, setShowExplainButton] = useState(false);
  const [aiAnswer, setAiAnswer] = useState('');
  const [loadingAnswer, setLoadingAnswer] = useState(false);

  // New state and refs for positioning
  const mainContentRef = useRef(null);
  const [selectionRelativeTop, setSelectionRelativeTop] = useState(0);

  useEffect(() => {
    const loadLessonContent = async () => {
      if (!lessonId) {
        console.warn('lessonId is missing');
        setLoading(false);
        return;
      }

      try {
        const lessonRef = doc(
          firestore,
          'courses',
          courseId,
          'chapters',
          chapterId,
          'lessons',
          lessonId
        );
        const lessonDoc = await getDoc(lessonRef);

        if (lessonDoc.exists()) {
          const lessonData = lessonDoc.data();
          setLessonContent(lessonData);
          setLessonTitle(lessonData.title || 'Lesson');
          console.log('Lesson data found');
        } else {
          console.error('Lesson not found');
        }
      } catch (error) {
        console.error('Error fetching lesson content:', error);
      } finally {
        setLoading(false);
      }
    };

    loadLessonContent();
  }, [courseId, chapterId, lessonId]);

  // Mouseup event listener to detect text selection and show "Explain with AI" button
  useEffect(() => {
    const handleMouseUp = (e) => {
      // Ignore selection if it's within an input or textarea
      if (['INPUT', 'TEXTAREA'].includes(e.target.tagName)) {
        return;
      }

      const selection = window.getSelection();
      const selectedText = selection.toString().trim();

      if (selectedText) {
        setHighlightedText(selectedText);

        // Get the bounding rectangle of the selection
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        // Set the position of the button
        setButtonPosition({
          x: rect.left + window.scrollX,
          y: rect.top + window.scrollY - 30, // Adjust y to appear above selection
        });

        setShowExplainButton(true);
      } else {
        setShowExplainButton(false);
      }
    };

    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleAnswer = (selectedOption, correctAnswer) => {
    if (selectedOption === correctAnswer) {
      setUserAnswer('Correct!');
    } else {
      setUserAnswer('Incorrect, try again.');
    }
  };

  const handleCompleteLesson = async () => {
    try {
      if (!currentUser) {
        throw new Error('User is not authenticated');
      }
      await storeCompletedLesson(currentUser.uid, courseId, lessonId);

      console.log(`Lesson ${lessonId} marked as completed!`);

      navigate(-1);
    } catch (error) {
      console.error('Error marking lesson as completed:', error);
    }
  };

  // AI call handler for explaining highlighted text
  const handleExplain = async () => {
    if (!highlightedText) return;

    // Calculate the position of the AI response box here
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const mainContentRect = mainContentRef.current.getBoundingClientRect();
      const relativeSelectionTop = rect.top - mainContentRect.top + mainContentRef.current.scrollTop;
      setSelectionRelativeTop(relativeSelectionTop);
    }

    setLoadingAnswer(true);

    try {
      const response = await fetch(
        'https://us-central1-avantdemy2.cloudfunctions.net/explainText',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ highlightedText }),
        }
      );

      if (!response.ok) {
        throw new Error('Error fetching AI explanation');
      }

      const data = await response.json();
      setAiAnswer(data.explanation);
    } catch (error) {
      console.error('Error:', error);
      setAiAnswer('Sorry, an error occurred while fetching the explanation.');
    } finally {
      setLoadingAnswer(false);
      setShowExplainButton(false);
      setHighlightedText('');
    }
  };

  // Helper function to strip LaTeX delimiters
  const stripDelimiters = (latex) => {
    let trimmed = latex.trim();
    if (trimmed.startsWith('\\(') && trimmed.endsWith('\\)')) {
      return trimmed.substring(2, trimmed.length - 2);
    }
    return trimmed;
  };

  // Helper function to render text with inline math
  const renderTextWithMath = (text) => {
    const parts = text.split(/\\\((.*?)\\\)/g); // Matches \( ... \)
    return parts.map((part, idx) => {
      if (idx % 2 === 1) {
        const latex = part.replace(/\\\\/g, '\\');
        return <InlineMath key={idx} math={latex} />;
      } else {
        return part;
      }
    });
  };

  // Function to render content based on its type
  const renderContent = (contentItem) => {
    switch (contentItem.type) {
      case 'subtitle':
        return (
          <h2 key={contentItem.data} className="text-2xl font-semibold mb-3">
            {contentItem.data}
          </h2>
        );

      case 'text':
        return (
          <p key={contentItem.data} className="text-base text-gray-700 mb-4">
            {renderTextWithMath(contentItem.data)}
          </p>
        );

      case 'steps':
        return (
          <div key={contentItem.data.title} className="mb-6">
            <h3 className="text-xl font-semibold mb-2">
              {renderTextWithMath(contentItem.data.title)}
            </h3>
            <ol className="list-decimal list-inside mb-2">
              {contentItem.data.steps.map((step, index) => (
                <li key={index} className="mb-2">
                  {renderTextWithMath(step.text)}
                  {step.equation && (
                    <BlockMath
                      math={stripDelimiters(step.equation.replace(/\\\\/g, '\\'))}
                    />
                  )}
                </li>
              ))}
            </ol>
            {contentItem.data.conclusion && (
              <p className="text-base italic text-gray-600">
                {renderTextWithMath(contentItem.data.conclusion)}
              </p>
            )}
          </div>
        );

      case 'graph':
        return (
          <div className="mb-6">
            {contentItem.data.title && (
              <h3 className="text-xl font-semibold mb-2 text-center">
                {renderTextWithMath(contentItem.data.title)}
              </h3>
            )}
            <GraphComponent
              equation={contentItem.data.equation}
              xRange={contentItem.data.xRange}
              step={contentItem.data.step}
            />
            {contentItem.data.equation && (
              <div className="text-center mt-2">
                <InlineMath math={`y = ${contentItem.data.title}`} />
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <div className="min-h-screen flex flex-col bg-white relative">
        <main className="flex-grow container mx-auto px-4 py-8 max-w-6xl">
          <div>
            <h1 className="text-3xl font-bold text-gray-800 mb-8">
              {lessonTitle}
            </h1>
            <hr className="border-t border-gray-300 mb-8" />
          </div>
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <p className="text-lg text-gray-700">Loading lesson content...</p>
            </div>
          ) : (
            lessonContent && (
              <div className="flex">
                {/* Main Content Column */}
                <div
                  ref={mainContentRef}
                  className="w-2/3 pr-4 mx-auto max-w-3xl text-left"
                >
                  {lessonContent.content.map((contentItem, index) => (
                    <div key={index} className="mb-6">
                      {renderContent(contentItem)}
                    </div>
                  ))}

                  {/* User Answer Feedback */}
                  {userAnswer && (
                    <div className="mb-4">
                      <p
                        className={`text-lg font-medium ${
                          userAnswer === 'Correct!'
                            ? 'text-green-600'
                            : 'text-red-600'
                        }`}
                      >
                        {userAnswer}
                      </p>
                    </div>
                  )}

                  {/* Mark as Complete Button */}
                  <button
                    onClick={handleCompleteLesson}
                    className="mt-4 bg-black text-white px-6 py-2 rounded hover:bg-gray-700 transition-colors duration-200"
                  >
                    Finish Lesson
                  </button>
                </div>

                {/* AI Response Column */}
                <div className="w-1/3 pl-4 relative">
                  {aiAnswer && (
                    <div
                      className="bg-white border border-black p-4 rounded shadow mb-6 absolute w-full"
                      style={{ top: selectionRelativeTop }}
                    >
                      <h3 className="text-lg font-semibold mb-2">
                        AI Explanation:
                      </h3>
                      <p>{aiAnswer}</p>
                      {/* <button
                        onClick={() => setAiAnswer('')}
                        className="mt-2 text-blue-600 underline"
                      >
                        Close
                      </button> */}
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </main>

        {/* "Explain with AI" Button */}
        {showExplainButton && (
          <div
            style={{
              position: 'absolute',
              top: buttonPosition.y,
              left: buttonPosition.x,
              zIndex: 1000,
            }}
          >
            <button
              onClick={handleExplain}
              className="bg-blue-600 text-white px-2 py-1 rounded"
              disabled={loadingAnswer}
            >
              {loadingAnswer ? 'Explaining...' : 'Explain with AI'}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default LessonContentScreen;

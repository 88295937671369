import React from 'react';
import { format } from 'date-fns';
import { LockIcon, ChevronRightIcon } from 'lucide-react';

const ChapterComponent = ({ title, index, locked, unlockDate, onClick }) => {
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'MMMM dd, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };

  return (
    <div className="max-w-2xl mx-auto w-full p-2">
      <button
        onClick={onClick}
        disabled={locked}
        className={`
          w-full
          px-6 
          py-4
          rounded-lg 
          border
          transition-colors
          duration-150
          ease-in-out
          relative
          flex
          items-center
          justify-between
          ${locked 
            ? 'bg-gray-50 border-gray-200 hover:bg-gray-100' 
            : 'bg-white border-gray-200 hover:border-blue-500 hover:bg-blue-50'
          }
          disabled:cursor-not-allowed
          group
        `}
      >
        <div className="flex-1 text-left">
          <div className="flex items-center gap-2">
            {locked && <LockIcon className="h-4 w-4 text-gray-400" />}
            <span className="font-medium text-gray-900">
              {`${index + 1}. ${title}`}
            </span>
          </div>
          
          {locked && unlockDate && (
            <p className="mt-1 text-sm text-gray-500">
              Unlocks on: {formatDate(unlockDate)}
            </p>
          )}
        </div>

        <div className="flex items-center ml-4">
          {!locked && (
            <div className="flex items-center text-sm text-gray-600 group-hover:text-blue-600">
              <span>View Lessons</span>
              <ChevronRightIcon className="h-4 w-4 ml-1" />
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

export default ChapterComponent;
// src/components/Cancel.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const CancelScreen = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center h-screen bg-red-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-3xl font-bold text-red-600 mb-4">Payment Canceled</h1>
        <p className="mb-6">Your payment was canceled. You can try again anytime.</p>
        <button
          onClick={() => navigate('/upgrade')}
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default CancelScreen;

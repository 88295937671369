import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // You can remove this if you don't need basic styles
import App from './App';


//entry point of the application
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
